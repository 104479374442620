body{
    background-color: #ffffff !important;
    font-family: 'Poppins', sans-serif !important ;
}
.userlandtop-div{
    height: 92px;
    background: inherit;
    background-color: #83A275;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 1);
    border-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    /* width: 1532px; */
    /* position: sticky !important;
    overflow-y: hidden !important; */

     /* margin-left: -2px;  */
}
.clogo{
    width: 105px;
    height: 73px;
    margin-top: 8px;
}
.com-name{
    /* font-family: 'Merriweather Bold', 'Merriweather Regular', 'Merriweather', sans-serif; */
    
    font-weight: 700;
    white-space: nowrap;
    text-shadow: 2px 2px 5px rgb(0 0 0 / 65%);
    font-style: normal;
    font-size: 23px;
    letter-spacing: 0.5px;
    color: #FF8427;
    margin-top: 26px;
    position: absolute;
}
.gm-text{
    font-family: 'Source Sans Pro Bold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 17px;
    letter-spacing: 0.2px;
    color: #FFF5EE;
    margin-top: 32px;
    position: absolute;

}
.proftop-div{
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-top: 15px;
    /* padding-top: 4px; */
    padding-bottom: 5px;
    padding-right: 5px;
    width: 219px;

}
.proftop-name{
    /* font-family: 'Source Sans Pro Bold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-family: 'Poppins', sans-serif ;

    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    color: #333333;
    margin-top: 4px !important;
}
.dropimg{
    margin-top: -5px;
}
.prof-img-right{
/* margin-top: -5px; */
}
.menu-name{
    font-family: 'Poppins', sans-serif ;

    /* font-family: 'Merriweather Black', 'Merriweather Regular', 'Merriweather', sans-serif; */
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    letter-spacing: normal;
    color: #214B0D;
    text-align: left;
    cursor: pointer;
}
.menu-name:hover{
    font-family: 'Poppins', sans-serif ;

    /* font-family: 'Merriweather Black', 'Merriweather Regular', 'Merriweather', sans-serif; */
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    letter-spacing: normal;
    color: #214B0D;
    text-align: left;
    cursor: pointer;
}
.borderbotomcontiner{
    border-bottom: 2px solid rgb(128, 130, 159) ;
    padding-top: 26px;
    padding-bottom: 5px;
    background-color: #ffffff;

}
.sagarsirimg{
    width: 67px;
    height: 57px;
}
.sagarsirimg1{
    width: 65px;
    height: 57px;
    margin-left: -11px;
}
.menuname-subtext{
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: normal;
    font-size: 10px;
    color: #391847;
}

.textdecorunder{
    border-bottom: 1px solid #FFFFFF;
    color: #ffffff;
    font-size:16px ;
    font-family: 'Poppins', sans-serif ;
    font-weight: 600;
    letter-spacing: normal;

}
.textdecorunder:hover{
    border-bottom: 1px solid #FFFFFF;
    color: #ffffff;
    font-size:16px ;
    font-family: 'Poppins', sans-serif ;
    font-weight: 600;
    letter-spacing: normal;
    text-decoration: none;
}
.profpercent{
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: italic;
    font-size: 10px;
    color: #391847;
}
.colpletprof-spn{
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: italic;
    font-size: 10px;
    color: #0000FF;
}
.radius-heding{
    font-family: 'Poppins', sans-serif ;
    font-weight: 500;
    font-style: normal;
    color: #212529;
    font-size: 15px;

}
.seealltxt{
    font-family: 'Poppins', sans-serif ;
    font-weight: 500;
    font-style: normal;
    color: #FF8427;
    font-size: 12px;
    margin-left: 15px;
}
.showconnectmemrs{
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: italic;
    font-size: 12px;
    color: #555555;
}
.firstcircle{
/* border-radius: 50%;
border: 3px solid #333333;
width: 80px;
height: 71px;
padding-right: 3px;
padding-bottom: 4px; */
border: 15px solid rgba(248, 248, 255, 1);
   border-radius: 50%;
   height: 105px;
   width: 105px;
   position: relative;
   
}
.secondcircle{
    /* border-radius: 50%;
    border: 3px solid lightgray;
    width: 66px;
    height: 59px;
    margin-top: 5px;
    margin-left: 5px; */
    position: absolute;
    background-color:  rgba(248, 248, 255, 1);
   border-radius: 50%;
   border: 7px solid #B5924C;
   height: 82px;
   width: 82px;
   /*
    Put top edge and left edge in the center
   */
   top: 50%;
   left: 50%;
   margin: -41px 0px 0px -41px;
}
.radisval{
    margin-top: 20px;
    margin-left: 20px;
}
.MuiSlider-root{
    color:#B5924C !important;
    height: 3px !important;
    width: 80% !important;
}

    

.leftdivuser{
    /* height: 523px; */
    overflow-y: scroll;
    /* max-width: 23%!important; */
    background-color: #FFFFFF;
}
.MuiSlider-markLabel{
    font-family: 'Poppins', sans-serif ;
    font-weight: 400 !important;
    font-style: italic !important;
    font-size: 12px  !important;
    color: #333333 !important;

}
.MuiSlider-rail {
    width: 100%;
    height: 2px;
    display: block;
    opacity: 0.40;
    position: absolute;
    border-radius: 1px;
    background-color: rgb(78, 78, 78) !important;
}
.startmark{
    width: 12px;
    height: 12px;
    display: flex;
    outline: 0;
    position: absolute;
    box-sizing: border-box;
    margin-top: -5px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    margin-left: -6px;
    border-radius: 50%;
    justify-content: center;
    background-color:#FF8427 !important;
    
}

.newsfeeddiv{
     background-color: rgba(248, 248, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 1);
    border-radius: 0px;
    /* height: 523px; */
    overflow-y: scroll;
}
.heightforAll3Div{
    height: 615px ;
    overflow-y: scroll;

}
.userland-pop-textbox{
    margin-left: 5px;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 25px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: italic;
    font-size: 12px;
    height: 48px;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 25px;
    -moz-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.34901960784313724);
    -webkit-box-shadow: 2px 2px 2px rgb(0 0 0 / 35%);
    box-shadow: 2px 2px 2px rgb(0 0 0 / 35%);
    color: rgb(153, 153, 153);

}
.eventlogoimg{
    width: 45px;
    border-radius: 50%;

}
.eventlogoimg1{
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
.event-location{
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: italic;
    font-size: 13px;
}
.event-type-text{
    font-family: 'Poppins', sans-serif ;
    font-weight: 600;
    font-style: normal;
    color: #555555;
    font-size: 15px;

}
.evntxt{
    font-family: 'Poppins', sans-serif ;
    font-weight: 600;
    font-style: normal;
    color: #B5924C;
    font-size: 15px;
}
.event-date{
    font-family: 'Poppins', sans-serif ;
    font-weight: 300;
    font-style: normal;
    font-size: 10px;
}
.event-publisher{
    font-family: 'Poppins', sans-serif ;
    font-weight: 300;
    font-style: normal;
    font-size: 10px;
    margin-left: 23px;
}
.event-date-div{
    position: absolute;
    white-space: nowrap;
    margin-top: 24px;
    margin-left: 59px !important;

}
#image-grid {
    display: grid;
    grid-gap: .5em;
    grid-template-areas:
    "photoone phototwo photothree"
    "photoone photofour photofive";

    grid-template-columns: 1fr 20% 20%;
  }
  #image-grid > div:first-child{
    grid-area: photoone;
    height: 350px;

  }
  
  .img1{
width: 97%;
height: 340px;
  }
  .img2{
    width: 280px;
}
.img3{
    width: 280px;
}
.cardiv-event{
    background-color: #ffffff;
    /* width: 98%; */
    padding-top: 1px;
    padding-bottom: 15px;
    margin-top: 35px;
    
}
.cardiv-event1{
    background-color: #B5924C;
    width: 98%;
    padding-top: 1px;
    padding-bottom: 15px;
    margin-top: 35px;
    
}
  
/* Anything udner 1024px */

.event-sub-detail{
    font-family: 'Poppins', sans-serif ;
    font-weight: 700;
    font-size: 13px;
    color: #555555;
}
.event-sub-detail1{
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: #555555;
}
.likespn{
    font-family: 'Poppins', sans-serif ;
    font-weight: 700;
    font-size: 10px;
    color: #555555;


}
.report-spm{
    
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: #FF8427;

}
.report-spm-div{
    background-color: rgba(254, 253, 250, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 130px !important;
    height: 40px !important;
    padding-top: 9px;
    text-align: center;
    white-space: nowrap;
    max-width: 22%;
}
.menu-veryfyprof{
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: normal;
    font-size: 10px;
    color: rgba(242, 242, 242, 0.9686274509803922)
    
}
.menu-veryfydiv{
    background: inherit;
    background-color: #214B0D;
    border: none;
    border-radius: 3px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    width: 90px;
}
.notifiicon{
    margin-top: 32px;
    position: absolute;
    margin-left: 89px;
    cursor: pointer !important;
    color:rgba(242, 242, 242, 0.9686274509803922)
}
.notifiicon:hover{
    margin-top: 32px;
    position: absolute;
    margin-left: 89px;
    /* color: #B5924C; */
    cursor: pointer !important;
}
.notifi-text{
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: rgba(242, 242, 242, 0.9686274509803922);
    margin-top: 19px;
    margin-left: 107px;

}
.notifi-text1{
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: rgba(242, 242, 242, 0.9686274509803922);
    margin-top: 19px;
    margin-left: 38px;

}
.activelike{
    color:#B5924C;
}
.drpbtn-menu {
    background-color: transparent !important;
    border: none !important;
}
.drpdn-meni-item{
    margin-left: 9px !important;
    margin-top: 0px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 13px;
    color: #214B0D;
    width: 228px;
    margin-top: -10px;
}
.drpdiv{
    margin-left: 15px;
    z-index: 1 !important;
}
.drp-item{
    font-family: 'Poppins', sans-serif ;
    font-weight: 600 !important;
    font-style: normal;
    font-size: 13px !important;
    color: #391847 !important;
    z-index: 1 !important;
    margin-left: 0px;

}
.menu-veryfyprof{
    font-family: 'Poppins', sans-serif ;
    font-weight: 300;
    font-style: italic;
    font-size: 10px;
    color: #B5924C;
    margin-left: 6px;

}
.cupicon-top{
    position: absolute;
    margin-top: 32px;
    margin-left: 190px;
    color:rgba(242, 242, 242, 0.9686274509803922)
}
.cupicon-top:hover{
    position: absolute;
    margin-top: 32px;
    margin-left: 190px;
    color: #B5924C;
}
.eventdivopen-popup{
    /* width: 696px; */
    height: 131px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 5px;
    -moz-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.34901960784313724);
    -webkit-box-shadow: 2px 2px 2px rgb(0 0 0 / 35%);
    box-shadow: 2px 2px 2px rgb(0 0 0 / 35%);
}
.radiobtn-text1{
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: #555555;

}
/* for radion btn */
.radiodivcust {
    display: block;
    position: relative;
    padding-left: 32px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .radiodivcust input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0px;
    left: 0;
    height: 17px;
    width: 17px;
    /* background-color: #e1e0e0; */
    border: 2px solid #bebcbc ;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .radiodivcust:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .radiodivcust input:checked ~ .checkmark {
    background-color: #FF8427;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .radiodivcust input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .radiodivcust .checkmark:after {
    top: 4px;
    left: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: white;
  }

/*for chechbox */
.chechboxxcustom {
    display: block;
    position: relative;
    padding-left: 32px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .chechboxxcustom input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark1 {
    position: absolute;
    top: 1px;
    left: 0;
    height: 16px;
    width: 16px;
    border: 2px solid #bebcbc ;

    /* background-color: #eee; */
  }
  
  /* On mouse-over, add a grey background color */
  .chechboxxcustom:hover input ~ .checkmark1 {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .chechboxxcustom input:checked ~ .checkmark1 {
    background-color: #FF8427;
    border-color: #FF8427;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark1:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .chechboxxcustom input:checked ~ .checkmark1:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .chechboxxcustom .checkmark1:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 9px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

 .ckeckboxmatrplan .chechboxxcustom .checkmark1:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
 }

#sharediv1 input[type='radio']:after {
    width: 18px;
    height: 18px;
    border-radius: 15px;
    top: -4px;
    left: -3px;
    position: relative;
    /* background-color:  #0040DD; */
    content: '';
    display: inline-block;
    visibility: visible;
    /* border: 1px solid #0040DD; */
    
}

#sharediv1 input[type='radio']:checked:after {
    width: 18px;
    height: 18px;
    border-radius: 15px;
    top: -4px;
    left: -3px;
    position: relative;
    /* background-color: #0040DD; */
    content: '';
    display: inline-block;
    visibility: visible;
    /* border: 3px solid #0040DD; */
    
}
.blog-auth-text{
    font-family: 'Poppins', sans-serif ;
    font-weight: 300;
    font-style: normal;
    font-size: 10px;
    color: #F2F2F2;
    background-color: #B5924C;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 1px;
    padding-bottom: 2px;
}
.blogimg{
    height: 360px;
}
.img-source-spn{
    font-family: 'Poppins', sans-serif ;
    font-weight: 300;
    font-style: normal;
    font-size: 10px;
}
.blog-text{
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: #555555;
    width:480px;
    overflow:hidden !important;
    text-overflow: ellipsis;

}
.blog-textdiv{
    line-height: 1.1 !important;

}
.read-more-blog{
    font-family: 'Poppins', sans-serif ;
    font-weight: 600;
    font-style: italic;
    font-size: 13px;
    color: #0000FF;
    cursor: pointer;
}
.kyc-subheading{
    font-family: 'Poppins', sans-serif ;
    font-weight: 600;
    font-size: 13px;
    color: #555555;
}
.link-refrence{
    color: #0000FF;
    font-family: 'Poppins', sans-serif ;
    font-weight: 300;
    font-style: normal;
    font-size: 10px;
}
.kyc-ref{
    font-family: 'Poppins', sans-serif ;
    font-weight: 300;
    font-style: normal;
    font-size: 10px;
    line-height: 1.1;
}
.evntxt-birthday{
    font-family: 'Poppins', sans-serif ;
    font-weight: 700;
    font-style: normal;
    color: #F2F2F2;
}
.event-date-birthday{
    font-family: 'Poppins', sans-serif ;
    font-weight: 300;
    font-style: normal;
    font-size: 10px;
}
.birdtday-date-text{
    font-family: 'Poppins', sans-serif ;
    font-weight: 700;
    font-style: normal;
    font-size: 25px;
    color: #F2F2F2;
}
.birthday-date-img{
    width: 119px;
    height: 125px;
}
.birthday-date-img2{
    width: 119px;
    height: 125px;
    margin-left: -40px;
}
.birthday-date-img3{
    width: 119px;
    height: 125px;
    margin-left: -18px;
}
.birthday-date-nme{
    font-family: 'Poppins', sans-serif ;
    font-weight: 300;
    font-style: normal;
    font-size: 11px;
    color: #F2F2F2;
    white-space: nowrap;
}
.birthday-date-nme1{
    font-family: 'Poppins', sans-serif ;
    font-weight: 300;
    font-style: normal;
    font-size: 11px;
    color: #F2F2F2;
    white-space: nowrap;
    margin-left: -33px;
}
.birthday-date-nme2{
    font-family: 'Poppins', sans-serif ;
    font-weight: 300;
    font-style: normal;
    font-size: 11px;
    color: #F2F2F2;
    white-space: nowrap;
    margin-left: -36px;
}
.birthday-date-nme3{
    font-family: 'Poppins', sans-serif ;
    font-weight: 300;
    font-style: normal;
    font-size: 11px;
    color: #F2F2F2;
    white-space: nowrap;
    margin-left: -12px;
}
.radius-div{
    background-color: rgba(255, 255, 255, 1);
    padding-top: 5px;
     padding-left: 11px; 
    padding-bottom: 15px;
    width: 340px;
    padding-right: 7px;
    /* margin-left: -22px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    box-shadow: 
    0 -1px 4px rgba(0, 0, 0, 0.03), /* Top shadow */
    -2px 0 4px rgba(0, 0, 0, 0.05), /* Left shadow */
    0 2px 6px rgba(0, 0, 0, 0.05),  /* Bottom shadow */
    2px 0 6px rgba(0, 0, 0, 0.05);  /* Right shadow */
  border-radius: 8px; 
}
.radiussub-div{
    background-color: #FF84270D;
    padding: 5px;
    padding-left: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: -11px;
    margin-top: -7px;
    margin-right: -7px;
    border-top-left-radius: 8px; /* Adjust radius as needed */
    border-top-right-radius: 8px;
}
.radiussub-btn-div{
    background-color: #FF8427;
    padding: 5px;
    padding-left: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: -11px;
    margin-right: -7px;
    border-bottom-left-radius: 8px; /* Adjust radius as needed */
    border-bottom-right-radius: 8px;
}

    .radius-div11{
    background-color: rgba(255, 255, 255, 1);
    padding-top: 12px;
    padding-left: 25px;
    padding-bottom: 15px;
    width: 328px;
    padding-right: 7px;
    margin-left: -26px;
}
.searchdiv{
    border: 1px solid rgb(237, 236, 236);
padding-top: 3px;
border-radius: 1px;
color: rgb(153, 153, 153)
}
.communitydiv{
    /* height: 544px; */
    overflow-y: scroll;
}
.drpdowncmdirect{
    border-radius: 5px;
    background-color: rgb(218, 217, 217);
}
.communit-cardfirst{
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 3px;
    -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.34901960784313724);
    -webkit-box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
    box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
    /* color: #FF8427; */
}
.cardfirst-toprow{
    /* height: 44px; */
    height: auto;
    padding-bottom: 6px;
    background: inherit;
    background-color: #214B0D;
    border: none;
    border-radius: 3px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-top: 4px;
    
    
}
.cardfirst-toprow11{
    /* height: 44px; */
    height: auto;
    padding-bottom: 6px;
    background: inherit;
    background-color: #214B0D;
    border: none;
    border-radius: 3px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-top: 4px;
    margin-left: -15px !important;
    margin-right: -14px !important;
    
}
.community-direct-nametxt{
    font-family: 'Poppins', sans-serif ;
    font-weight: 600;
    font-style: normal;
    color: #FF8427;
    font-size: 13px;
}
.community-country{
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: #FFF5EE;
}
.communi-field{
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: italic;
    font-size: 13px;
    color: #FFF5EE;
}
.communi-field1{
    font-family: 'Poppins', sans-serif ;
    font-weight: 700;
    font-style: italic;
    font-size: 12px;
    color: #FFF5EE;
    height: 23px;
    background: inherit;
    background-color: rgba(211, 15, 69, 1);
    padding-left: 4px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 3px;
    letter-spacing: 0.5px;

}
.messg-div{
    font-family: 'Poppins', sans-serif ;
    font-weight: 600;
    font-style: normal;
    color: #B5924C;
    text-align: left;
    border: 1px solid #B5924C;
    height: 28px;
    border-radius: 5px;
    margin-left: -5px;
    margin-top: 4px;
    padding-top: 4px;
    
}
.communitydir-prof11{
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin-top: -40px;
}
.communitydir-prof{
    width: 160px;
    height: 160px;
    border-radius: 50%;
}
.community-viewprof{
    font-family: 'Poppins', sans-serif ;
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    color: #555555;

}
.community-viewprof:hover{
    font-family: 'Poppins', sans-serif ;
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    color: #0000FF;

}
.communitydrect-img{
    border-radius: 50%;
    width: 22px;
    height: 21px;
}
.communitydrect-img1{
    width: 29px;
    height: 20px;
}
.communtdrc-cit-text{
    font-family: 'Poppins', sans-serif ;
    font-weight: 600;
    font-size: 12px;
    color: #555555;

}
.comudrct-digitxt{
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    color: #555555;
    font-size: 12px;


}
.communityconnect-txt{
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: italic;
    font-size: 12px;
    color: #B5924C;
}
.cominvite-text{
    font-family: 'Poppins', sans-serif ;
    font-weight: 600;
    font-style: normal;
    color: #FEFDFA;
    margin-left: 7px;
}
.cuminvite-div{
    height: 28px;
    background: inherit;
    background-color: rgba(238, 118, 0, 1);
    border: none;
    border-radius: 5px;
    -moz-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.34901960784313724);
    -webkit-box-shadow: 3px 3px 3px rgb(0 0 0 / 35%);
    box-shadow: 3px 3px 3px rgb(0 0 0 / 35%);
    font-family: 'Poppins', sans-serif ;
    font-weight: 600;
    font-style: normal;
    color: #FEFDFA;
    margin-left: -11px;
}
.com-conect-btn{
    background-color: rgba(254, 253, 250, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Poppins', sans-serif ;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    color: #391847;
    width: 120px;
    margin-left: -58px;
    padding-top: 2px;
    padding-bottom: 2px;
}
.contct-infohover:hover{
color: #0000FF;
cursor: pointer;
}

.radius-secobd-text{
    font-family: 'Poppins', sans-serif ;
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
    color: #555555;
}
.radius-second-linktext{
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #0040DD;
}
.radius-fourth-subspn{
    font-family: 'Poppins', sans-serif ;
    font-weight: 300;
    font-style: italic;
    font-size: 10px;
    color: #B5924C;
    letter-spacing: 0.3px;
}
.serchbyorg{
    color: rgb(153, 153, 153);
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: normal;
    font-size: 10px;
    border: 1px solid lightgray;
    height: 26px;
    padding-top: 6px;

}
#dtheight input[type=select]{
    height: 26px !important;
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: normal;
    font-size: 10px;
    letter-spacing: 0.4px;
    color: #555555;
}
.height{
    height: 26px !important;
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: normal;
    font-size: 10px;
    letter-spacing: 0.4px;
    color: #555555;
    border-radius: 0px !important;

}
.radius-fourth-card1{
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 4px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
}
.radius-fourth-card1-lefttext{
    font-family: 'Poppins', sans-serif ;
    font-weight: 600;
    font-style: normal;
    font-size: 11px;
}
.radius-fourth-card1-text{
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: normal;
    font-size: 11px;
}
.radius-fourth-linktext{
    font-family: 'Poppins', sans-serif ;
    font-weight: 300;
    font-style: normal;
    font-size: 10px;
    color: #0000FF;
}
.bloodreq-head-div{
    background-color: rgba(248, 248, 255, 1);
}
.rightdiv-kycnote-text{
    color: #0000FF;
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: normal;
    font-size: 10px;

}
.sponser-text{
    font-family: 'Poppins', sans-serif ;
    font-weight: 300;
    font-style: italic;
    font-size: 10px;

}
.backimg-businessdirect{
    height: 30vh;
    margin-left: 30px;
    width: 80%;
     background-image: url('../../images/community1.png'); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* transition: calc(); */

    animation-name: animate;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-play-state: running;
     animation-timing-function: 5s;


}
 /* @keyframes animate {
    0%{
        background-image: url('../../images/community1.png');
    }
    50%{
        background-image: url('../../images/community2.png');
    }0%{
        background-image: url('../../images/community3.png');
    }
}  */
.seall-text{
    font-family: 'Poppins', sans-serif ;
    font-weight: 600;
    font-style: italic;
    font-size: 12px;
}
.message-text{
    font-family: 'Poppins', sans-serif ;
    font-weight: 400;
    font-style: italic;
    font-size: 12px;
    line-height: 80% !important;
}
.right-div{
    /* height: 523px !important; */
    overflow-y: scroll;
}
.borderforcomment{
    border: 1px solid rgba(242, 242, 242, 1);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.borderadius25px{
border-radius: 25px !important;
border: 1px solid rgba(242, 242, 242, 1) !important;

}
.withdrawattendbtn{
    background-color: rgba(238, 118, 0, 1) !important;
    color: #F2F2F2;
    border-radius: 5px;
    }
.withdrawattendbtn:hover{
background-color: rgba(238, 118, 0, 1) !important;
color: #F2F2F2;
}
.community-direct-nametxt11{
    font-size: 11px;
    color: #B5924C;
}
.galaryimgclass{
    height: 200px !important;
    overflow-y: scroll !important;
    display: inline-block !important;
}
.slider {
    margin:0 20px;
    overflow:"hidden";
    padding:1rem 0;
  }
  
  .slider img {
    width: 140%;
    border-radius:10px;
    object-fit: cover;
    height: 60px;

  }
  
  .react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
  }
  .react-multi-carousel-item {
    height: 100px !important;

    }
    .bVoXXg img{
        object-fit: cover;
border-radius: 50%px;
        height: 63px;

    }

   .sponserdiv .bVoXXg img{
        object-fit: cover;
border-radius: 3px;
        height: 200px !important;

    }
    .width697{
        width: 697px !important;
    }
    .disabledbtn{
        cursor: not-allowed !important;
        background-color: #e1dfdf;
        pointer-events: none;
        width: 270px;
    height: 48px;
    font-size: 14px;
    color: #555555;
    border: none;
    border-radius: 5px;
    }
   .disableHostCoffee{
    cursor: not-allowed !important;
        /* background-color: #e1dfdf; */
        color: #333333 !important;
        /* pointer-events: none !important; */
   }
   .disableMessageText{
    cursor: not-allowed !important;
        /* background-color: #e1dfdf; */
        color: #333333 !important;
        /* pointer-events: none !important; */
        background-color: #ccc;
   }
    .hoverformesgcard{
        background-color: #e6e5e5;
    }
    .threedotblockunclock{
        padding: 6px;
        padding-top: 2px;
        padding-bottom: 2px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #FF8427;
    }
    /* matromony */
    .Matrimony-Top-div{
        background-color: #214B0D;
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(242, 242, 242, 1);
        border-radius: 0px;
        font-family: 'Poppins', sans-serif ;
        font-weight: 700;
    font-style: normal;
    font-size: 14px;
    color: #B5924C;
    padding-top: 12px;

    }
   .matrimon-div .nav-tabs .navlinkforverticaltab.active{
background-color: #214B0D !important;
color: #B5924C !important;

   }
.matrimon-div .nav-tabs .navlinkforverticaltab{
    background-color: #fff !important;
    color: #B5924C !important;
       }
.matrimon-div .nav-tabs .nav-item {
        margin-bottom: -1px;
        margin-left: 1px;
        margin-right: 1px;
    }
.matrimon-div  .horizontal-tabmenu{
background-color: rgba(242, 242, 242, 1) !important;
padding-top: 6px;
padding-bottom: 6px;

    }
    .matrimon-div .nav-tabs{
border-bottom: none !important;
    }
    .matrimon-div .nav-tabs .navlinkforverticaltab.active{
padding-bottom: 5px !important;
    }
    .matrimon-div .nav-tabs .nav-link{
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
padding-top: 0.4rem !important;
padding-bottom: 0.3rem !important;


    }
    .matrimon-div.nav-link.active{
        padding-top: 0.4rem !important;
padding-bottom: 0.3rem !important;
    }
    .myproffile-mtr-div{
        /* background-image: linear-gradient(270deg,#771e16,#ff8f85); */
        border-radius: 5px;
        padding-bottom: 10px;
 
        /* background: linear-gradient(270deg, rgba(238, 118, 0, 1) 50%, #214B0D 94%) !important;  */
        /* background: radial-gradient(circle, rgba(65,25,98,1) 28%, rgba(181,146,76,0.9697128851540616) 100%); */
        /* background: linear-gradient(360deg, #214B0D 68%, #FFFFFF 90%); */
         background: linear-gradient(
            to right, 
            rgba(33, 75, 13, 1) 0%,      /* Dark green on the left */
    /* rgba(33, 75, 13, 0.5) 24%,   Lighter green towards the middle */
    rgba(33, 75, 13, 0.5) 25%, 
    rgba(33, 75, 13, 0.5) 75%,  /* Light green in the center */
    /* Light green in the center */
    /* rgba(33, 75, 13, 0.5) 76%,   Lighter green towards the right */
    rgba(33, 75, 13, 1) 100%     /* Dark green on the right */     
); 


    }
    .backgroundcolor-card-matrimony{
        /* background: linear-gradient(270deg, rgba(238, 118, 0, 1) 50%, #214B0D 94%) !important; */
        color: #F2F2F2 !important;
        padding: 8px;
        padding-bottom: 12px !important;
        border-radius: 3px;
        /* border: 1px solid rgb(235, 235, 235); */
        box-shadow: 0px 4px 2px 0px  rgba(253, 247, 253, 0.15);
        height: 369px;
    }
    .backgroundcolor-card-matrimony .communtdrc-cit-text{
        color: #797979 !important;

    }
    .backgroundcolor-card-matrimony .comudrct-digitxt{
        color: #797979 !important;

    }
    .imagePreview {
        width: 100%;
        height: 160px;
        background-position: center center;
        background: url(http://cliquecities.com/assets/no-image-e3699ae….jpg);
        background-color: #fff;
        background-size: cover;
        background-repeat: no-repeat;
        display: inline-block;
        box-shadow: 0px -3px 6px 2px rgba(0,0,0,0.2);
    }
    .backgroundcolor-card-matrimony .css-b62m3t-container {
        color: #333333 !important;
        font-size: 12px !important;
    }
.heightformatrdt{
    width: 632px !important;
    height: 34px !important;
}
.heightformatrdtbusness{
    width: 376px !important;
    height: 36px !important;
}
.heightfordtbusness{
    width: 418px !important;
    height: 36px !important;
} 

.tblclassmatsubscrip .kBhHpT {
    margin-right: 0px !important;
    margin-left: 0px !important;
  
  }
  .tblclassmatsubscrip .gnaqej{
    margin-left: 0px !important;
      margin-right: 0px !important;
  }   
  .busnessdrectory .bVoXXg img{
    object-fit: cover !important;
    width: 100% !important;
border-radius: 3px;
    height: 110px;

}
.rsis-image {
    border-radius: 4px !important;
}
.bussbodertop{
    border-top: 1px solid lightgray !important;
}
.boxshadow{
box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.34901960784313724) !important
}
/* for Delete popup */
/* Popup style */
.popup-box12{
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 111;
  }
  
  .box12 {
    position: relative;
    width: 30%;
    margin: 0 auto;
    height: 289px;
    max-height: 75vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    margin-top: 207px;
    margin-left: 516px; 
 }
 .heightfrjob{
    height: 210px !important;
 }
  
  .close-icon12 {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }
  .Deletpopdiv{
background-color:#FF453A;
margin-left: -20px;
    margin-right: -20px;
    padding: 10px;
    margin-top: -20px;
    text-align: center;
  }
  .Deletpopdiv1{
    background-color:#FF453A;
    margin-left: -14px;
        margin-right: -14px;
        padding: 10px;
        margin-top: -20px;
      }
  .warnspn{
    font-size: 18px !important;
    font-weight: bold;
    color: white;
  }
  .warntxtdiv{
margin-top: 17px;
  }
  .warntxt{
font-size: 15px !important;
color: #555555;
  }
  .warnbtn{
      margin-top: 25px;
      margin-left: 20px;
  }
  .cnceldeletpopbtn{
    width: 133px;
    border-radius: 4px;
    padding-top: 0px;
    height: 36px;
    margin-right: 40px;
    color: #B5924C;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(181, 146, 76, 1);
    border-radius: 5px;
    
    letter-spacing: 0.2px;
    color: #B5924C;
  }
  .addeletepopbtn{
    width: 133px;
    border-radius: 4px;
    background-color: #411962;
    height: 36px;
    color: white !important;
  }
  /* Popup style */
.popup-box22{
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 111;
  }
  
  .box22 {
    position: relative;
    width: 26%;
    margin: 0 auto;
    height: 213px;
    max-height: 75vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    margin-top: 207px;
    margin-left: 676px; 
 }
  
  .close-icon22 {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }
  .Deletsuccespopdiv{
background-color:#30D158;
margin-left: -20px;
    margin-right: -20px;
    padding: 10px;
    margin-top: -20px;
  }
  .warnspn{
    font-size: 18px !important;
    font-weight: bold;
    color: white;
  }
  .warntxtdiv{
margin-top: 17px;
  }
  .warntxt{
font-size: 15px !important;
color: #555555;
  }
  .warnbtn{
      margin-top: 25px;
  }
  
  .addeletepopbtn{
    width: 133px;
    border-radius: 4px;
    background-color: #411962;
    height: 36px;
    color: white !important;
  }
  /* for react dropdown font */
  .css-1jqq78o-placeholder{
    font-size: 13px !important;
    color: #555555 !important;

  }
  .drpbtn-menu.dropdown-toggle{
color: #ffffff !important;
font-size: 23px;
cursor: pointer;

  }
  .css-1dimb5e-singleValue{
    font-size: 13px !important;
    color: #555555 !important;

  }
  .boederbotomforcoffee{
    border-bottom: 1px solid #fff;
  }
 
  /* for show some  text in doted format */
  
  .textShowondot{
    display:inline-block;
    width:180px;
    white-space: nowrap;
    overflow:hidden !important;
    text-overflow: ellipsis;
}
/* for business */

.textShowondotBuss{
    display:inline-block;
    width:100px;
    white-space: nowrap;
    overflow:hidden !important;
    text-overflow: ellipsis;
}
.textShowondotAdharimg{
    display:inline-block;
    width:315px;
    white-space: nowrap;
    overflow:hidden !important;
    text-overflow: ellipsis;
}
.textShowondottopprofname{
    display:inline-block;
    width:137px;
    white-space: nowrap;
    overflow:hidden !important;
    text-overflow: ellipsis;
    margin-left: 16px;
    text-align: left;
}
.textShowondottoforEmail{
    display:inline-block;
    width:141px;
    white-space: nowrap;
    overflow:hidden !important;
    text-overflow: ellipsis;
    text-align: left;
}
.textShowondottoforBlogpost{
    display:inline-block;
    width:541px;
    white-space: nowrap;
    overflow:hidden !important;
    text-overflow: ellipsis;
    text-align: left;
}
.messengerprofile{
    width: 40px;
    height: 38px;
    border-radius: 50%;
}
.topprofsmimg{
    width: 35px !important;
  height: 32px !important;
  border-radius: 50%;
  margin-top: 3px;

}
.topprofMdimg{
    width: 58px !important;
  height: 57px !important;
  border-radius: 50%;
}
.widthdtt{
width: 320px !important;
}
.capitalizetxxxt{
    text-transform: capitalize;
}
.blurcardmat{
    /* background-color: lightgray !important; */
    border: 5px !important;
    /* background: #30242457; */
    /* cursor: not-allowed; */
    pointer-events: none;
    background: rgb(131 112 112 / 12%);
    pointer-events: none;
    filter: blur(16px);



}
.borderstatusspnatt{
    border: 3px solid #4CAF50 ;
    border-radius: 50%;
    width: 103px;
    height: 102px;
 padding: 4px;
    box-sizing:border-box;
    background-color: #ffffff;
}
.borderstatusspnrej{
    border: 3px solid #F44336 ;
    border-radius: 50%;
    width: 103px;
    height: 102px;
 padding: 4px;
    box-sizing:border-box;
    background-color: #ffffff;
}
.borderstatusspnpen{
    border: 3px solid #FFC107 ;
    border-radius: 50%;
    width: 103px;
    height: 102px;
 padding: 4px;
    box-sizing:border-box;
    background-color: #ffffff;
}
.border-btn1
{
    border:1px solid !important;
   border-color:#F7F5F5 !important;
   padding-left: 35px !important;
   padding-bottom: 5px !important;
}
.marginleftrightminus{
    margin-right: -6px !important;
    margin-left: -17px !important;
}
.borderBottombtn{
    border-bottom: 1px solid #D9D9D9 !important;
    margin-left: -10px;
}
.minusleftmarg{
    margin-left: -12px !important;
}
.margleftmins{
    margin-left: -30px !important;
}
.minusleftmarg8{
margin-left: -9px;
}
.bghjHX{
    margin-top: 0px !important;
    margin-top: 0px !important;
    margin-left: -6px !important;
    margin-right: 0px !important;
}
.kNBmiK {
    overflow: hidden;
    margin: 0 0px 0px 0px !important;
}
.matchestxetxt{
    font-size: 11px;
    font-size: "roboto";
    font-weight: 500;
    color: #DD0000;
}
.sortbytxt{
    font-size: 11px;
    font-weight: 400;
    color: #626262;
}
.fontroboto40012px{
    font-size: 12px;
    font-weight: 400;
    color: #222227;
    font-family: "Roboto", sans-serif;
}