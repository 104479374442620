body {
background-color: #F8F8FF !important;
line-height: 1.6 ;
font-family: 'Poppins', sans-serif !important ;
font-size: 12px;

}

.popinsfont{
  font-family: 'Poppins', sans-serif;
}
.fontpopinrem{
  font-size: .81568rem;
}
.data-table-extensions-filter{
  /* margin-left: 25;
  margin-left: 894px; */
  float: right !important;
 
}


.data-table-extensions{
  width: 107% !important;
   margin-left: -78px; 
}
.data-table-extensions > .data-table-extensions-filter{
  float: right !important;
  padding-top: 12px ;
}

.data-table-extensions > .data-table-extensions-action > button.download {
  display: none !important;
}
.iodownload{
margin-left: 0px;
margin-top: 7px;
color: #A47355;
}
.iodownload1{
  margin-left: 73px;
margin-top: 7px;
color: #A47355;
}


.data-table-extensions > .data-table-extensions-filter > .icon {
  /* float: left; */
  position: relative;
  display: block;
  top: 11px !important;
  margin-left: 2px;
  width: 20px;
  height: 24px;
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAB80lEQVQ4T73Uv2tTURQH8O85972kxpqWNG2gOKhb21X8hXYQxCEKLp3cXNw6OIm0zTWWVjo4Cv4BIlIEF4c6FEQQi4uLZNTFYvNDiwWN9713jrxKQZOXBNLiXd89H77n3Hcv4YAXHbCH/wuOWh3kKJhSEDvjVbYtbffqKDFhrqRZ1ui+IrrB4PQfRCIieorAv1Vdoq1OcBsYY4jCV6Q0aAxuS9O8TGUQhRJeUGBZoTmQf65+lzaT0DYwvxA+1AiXBsSc+bxMjb+Lxq1mnITrpFKr3Utf7QnGMxNxdQNzvVr2niUVjFh3ioU2WMITW4uHPrbu+SdhYd6dFqK32vSy9RXaSZ6T0uiCawDmZq3srXYFx+aDs0p447N3eNPSj06Dz881a2Bvtl72nnQFh60O++LqBC5Wy/5aEpizvyaN8AcRmWwspitdwfjjWMk9VqUJn73zbSln1IxMuBcMztTK/nTPQ9kF72hBvGADoC/KOtuw/juANE5GggcMvkyq6xnjFz9ZavZMGG/Il3ScxD0C8RVAvqloRGzyAF6TaqBEFwWydoRT11rRrne5MPfzuLB/UgFPJXofz+yY1YEdcc/jpEloX49DK9qopIpYpSjuri8wLtxDAUwHnDr63dLXfYG7hzGjJjuFoT1s/2DCf9N3y51u0W9RndcVBJTNUgAAAABJRU5ErkJggg==); */
  background-image: url("https://www.flaticon.com/free-icons/search") !important;

  background-repeat: no-repeat;
  background-position: left center;
}

.data-table-extensions-action{
   margin-top: -93px; 
}

.data-table-extensions > .data-table-extensions-filter > .filter-text {
  border: 0;
  border-bottom: 1px solid black !important;
  outline: none;
  padding: 4px;
  margin-left: 21px !important;
  background-color: transparent;
  border-radius: 0 !important;
  
}
.data-table-extensions-action{
  margin-top: -34px !important;
}
.data-table-extensions > .data-table-extensions-action > button {
margin-right: -200px !important;

}
.data-table-extensions > .data-table-extensions-filter > .filter-text {
  border: 0;
  border-bottom: 1px solid black !important;
  outline: none;
  padding: 4px;
  margin-left: 21px !important;
  background-color: transparent;
  border-radius: 0 !important;
  
}

.filter-text ::placeholder {
  content: 'Search' !important;
  color :red !important
}

.fSAFyE {
  position: relative;
  width: 112% !important;

  overflow-x: hidden !important;
  margin-left: 20px !important;
}

.bjfIGC {
  position: relative;
  display: table;
  width: 100%;
  height: auto !important;
}

.rdt_TableHeadRow{
    background-color: #f8f8f8!important;
    color: #404040 !important;
}

.rdt_Table{
  width: 110%;
}
.hkxIgn{
  font-family: 'Poppins', sans-serif !important ;
  font-size: 11px !important;
font-weight: 600 !important;
border-width: 1px !important;
    border-style: solid !important;
    border-color: rgba(250, 251, 252, 1) !important;

}
.ekpVOf {
  font-size: 11px;
  font-weight: 400;
  color: #333333 !important;
  font-family: 'Poppins', sans-serif !important ;

}
.rdt_TableCol{
  font-size: 12px !important;
  font-family: 'Poppins', sans-serif !important ;

}
.rdt_TableCell{
text-transform: capitalize;
font-size: 12px !important;

}
.data-table-extensions > .data-table-extensions-action > button.print {
  background-image: none !important;
  background-position: center 4px;
  margin-left: 20px !important;
  display: none;

}


.ikGBKN{
margin-top: 5px;
}
.data-table-extensions{
  margin-top: -11px;
}
.sc-jrQzAO:nth-child(odd) {
  background-color: rgba(0,0,0,.03) !important;
}

.data-table-extensions > .data-table-extensions-action > .dropdown.drop {
  margin-right: -200px !important;
}
.data-table-extensions > .data-table-extensions-filter > .icon {

  margin-right: -15px !important;
}
.rdt_Pagination{
  position: relative;
}
/* .bhoXfZ{
  width: 41% !important;
    margin-top: -280px;
    margin-right: 112px;
    margin-left: -27px;
    border-style:none !important;
    background-color: none !important;
} */
.bWTWQu {
  /* margin: 0 4px;
  margin-top: -624px;
  margin-left: -680px;
  margin-right: 645px; */
  
  /* margin-top: -543px  !important; */
}
  

.fQmYxz {
  position: relative;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: inherit;
  color: inherit;
  margin-top: 1px;
  /* margin-top: -538px ; */
  /* margin-left: -237px; */
  margin-right: 630px !important;
     margin-left: -6px !important; 
}

.kBhHpT {
  margin-right: 718px !important;
  margin-left: -36px !important;

}
.gnaqej{
  margin-left: -720px !important;
    margin-right: 537px !important;
}

.data-table-extensions > .data-table-extensions-filter > .icon {

  content : url('../src/images/search1.png') !important; 
  color: gold !important; 

}
.eUeqdG {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight:bold;
  color: #545353;
}
.bMgaAx  {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.form-control {
  /* font-family: 'Source Sans Pro', sans-serif !important; */
  font-family: 'Poppins', sans-serif;

}
/* .container-fluid {
  width: 100%;
  padding-right: 88px !important;
  padding-left: 88px !important;
  margin-right: auto;
  margin-left: auto;
} */
  
.containerfluidpadding0{
  padding-left: 0px !important;
  padding-right: 0px !important;
  overflow-y: hidden !important;
}
.opensansfont{
  font-family: 'Open Sans';
}
.merriweatherfont{
  font-family: 'Merriweather', serif;
  letter-spacing: 0.3px;

}
.sourcesansfont{
  font-family: 'Source Sans Pro', sans-serif;
}
.textleft{
  text-align: left !important;
}
.textright{
  text-align: right !important;
}
.textcenter{
  text-align: center !important;
}
.cpointer{
  cursor: pointer;
}
.marginleft4{
margin-left: 3px;
}
.marginleft5{
  margin-left: 5px;
}
.marginleft15{
  margin-left: 85px !important;
}
.marginleft1{
  margin-left: 18px !important;
}
.marginleft2{
  margin-left: 10px !important;
}
.marginleft15{
  margin-left: 85px !important;
}
.marginleft3{
  margin-left: 55px !important;
}

.marginleft6{
  margin-left: 35px !important;
}
.margintpmins{
  margin-top: -3px !important;
}
.margintpmins1{
  margin-top: -15px !important;
}
.margintpmins2{
  margin-top: -10px !important;
}
.marginleftmins{
  margin-left: -24px !important;
}
.marginleftmins4{
  margin-left: -18px !important;
}
.marginleftmins5{
  margin-left: -9px !important;
}
.marginleftmins1{
  margin-left: -48px !important;
}
.marginleftmins6{
  margin-left: -35px !important;
}
.marginleftmins2{
  margin-left: -69px !important;
}
.marginleftmins3{
  margin-left: -89px !important;
}
.primary_button {
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.2px;
  color: #FFFFFF;
  text-align: center;
}
.margintop{
  margin-top:25px;
}
.margintop1{
  margin-top:5px;
}
.margintop2{
  margin-top:8px;
}
.margintop3{
  margin-top:15px !important;
}
.margintop4{
  margin-top:30px !important;
}

.margintop5{
  margin-top: 9px !important;
}
.news-readmor{
display: inline-flex !important; 
}
.positionfix{
  position: fixed;
}
.hide{
  display: none !important;
}
.displyinline{
display: inline-flex !important;
}
.displyflex{
  display: flex !important;
}
.flexwrapno{
  flex-wrap: nowrap !important;
}
.whitenowrap{
  white-space: nowrap !important;
}
.colororg{
  color: rgba(238, 118, 0, 1) !important;
}
.marglrftrightminusrow{
  margin-left: -16px !important;
  margin-right: -16px !important;

}
.react-datepicker__input-container {
  font-family: 'Poppins', sans-serif !important;
  color: #555555 !important;

 
}
select.form-control{
  -webkit-appearance: menulist!important;
  -moz-appearance: menulist!important;
  -ms-appearance: menulist!important;
  -o-appearance: menulist!important;
  appearance: menulist!important;
}

.filrerUserDiv{
  height: 200px;
  overflow-y: scroll;
}

.fwKvpK{
  color: #555555 !important;
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  margin-left: -10px !important;

}
.data-table-extensions > .data-table-extensions-filter > .filter-text {
  font-size: 13px !important;
  font-family: 'Poppins', sans-serif !important;

}

.divCenter{
  /* text-align: center; */
    border-bottom: 1px solid orange;
    margin-bottom: 16px;
}
.divCenter11{
  border: 1px solid orange;
    margin-bottom: 16px;
    border-radius: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
}
.divActive{
  
    /* box-shadow: */
      /* 18px 0 15px -4px rgb(181, 146, 76),
      -15px 0 8px -4px rgb(181, 146, 76) !important; */
    width: 100px;
    height: 100px;
    /* margin: 50px; */
    border: 2px solid #B5924C !important;
    background-color: #fffbe8 !important;
  
}
.width336{
  width: 336px !important;
}