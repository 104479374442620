/* Popup style */
.popup-boxmessage {
    position: fixed;
    /* background: #00000050; */
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 11111;
  }
  
  .boxmessage {
    position: relative;
    width: 24%;
    margin: 0 auto;
    /* height: 560px; */
    height: auto;
    max-height: 61vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 14px;
    border: 1px solid #999;
    overflow: auto;
    margin-top: 266px;
    margin-left: 754px;
  }
  .boxmessageexp{
    position: relative;
    width: 34%;
    margin: 0 auto;
    height: 750px;
    max-height: 82vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 14px;
    border: 1px solid #999;
    overflow: auto;
    margin-top: 126px;
    margin-left: 607px;
  }
  .close-iconmessage {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(28% - 30px);
    top: calc(109vh - 85vh - 33px);
    
    line-height: 20px;
    text-align: center;
    font-size: 19px;
color: rgba(238, 118, 0, 1);   
 width: 17px;
    height: 17px;
    border-radius: 5px;
    line-height: 20px;

    text-align: center;
    border: 1px solid rgba(238, 118, 0, 1);
  }
  .messg-date-text{
    height: 24px;
    background: inherit;
    background-color: rgba(242, 242, 242, 0.9686274509803922);
    border: none;
    border-radius: 4px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    padding: 3px;

  }
  .bordernonemessg{
    border: none !important;
  }
  .messagemain-div{
    /* overflow-y: scroll !important; */
    height: 230px;
  }
  .messagemain-divexp{
    /* overflow-y: scroll !important; */
    height: 379px;
    
  }
  
  .messageDiv-divexp{
    height: 382px;
    overflow-y: auto;
    padding-bottom: 10px;

    /* background-color: "rgb(248, 244, 244)" !important; */
/* background-color: #f2f2f2; */
  }
  .messageDiv-div{
     height: 236px; 
    overflow-y: auto;
    padding-bottom: 10px;
    /* background-color: #f2f2f2; */
    /* background-color: rgb(175, 250, 175); */

  }
  .border1pxformessge{
    border: 1px solid rgb(225, 225, 225);
    margin-left: -13px !important;
    margin-right: -13px !important;
    font-family: 'Source Sans Pro', sans-serif !important;

  }
  .messgsend-btn{
    width: 84px;
    height: 27px;
    background: inherit;
    background-color: rgba(238, 118, 0, 1);
    border: none;
    font-family: 'Source Sans Pro', sans-serif !important;

    border-radius: 5px
  }
  .marginrightminusformefssg{
    margin-top: -13px !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;

  }
  .closemegpopicon{
    /* margin-left: 150px !important ; */
    margin-top: 20px;
  }
  .closemegpopiconexp{
    /* margin-left: 260px !important ; */
    margin-top: 20px;
  }
  .blurmsg{
filter: blur(2px);
  }