/* Popup style */
.popup-boxcontact {
    position: fixed;
    background: #00000060;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  .boxcontact {
    position: relative;
    width: 34%;
    margin: 0 auto;
    height: auto;
    max-height: 74vh;
    margin-top: calc(100vh - 85vh - 20px);
    border-radius: 4px;
    padding: 14px;
    overflow: auto;
    margin-top: 200px;
    margin-left: 492px;
    background: inherit;
    background-color: rgba(246, 248, 248, 1);
    border: none;
    border-radius: 5px;
    -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.34901960784313724);
    -webkit-box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
    box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
    text-align: left;

    height: 347px;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 5px;
    

  }
  
  .close-iconcontact {
    content: 'x';
    font-weight: bold;
    cursor: pointer;
    position: fixed;
    right: calc(37% - 30px);
    top: calc(140vh - 106vh - 33px);
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    font-size: 18px;
    color: #391847;
  }

  .popup-boxkycpending{
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1111;
  }

  .boxkycpending{
  position: relative;
  /* width: 70%; */
  margin: 0 auto;
  height: auto;
  max-height: 74vh;
  margin-top: calc(100vh - 85vh - 20px);
  border-radius: 4px;
  padding: 14px;
  overflow: auto;
  margin-top: 200px;
  /* margin-left: 492px; */
  background: inherit;
  color: #FFF5EE;
  background-color: rgb(147, 148, 148);
  border: none;
  border-radius: 5px;
  -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.34901960784313724);
  -webkit-box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
  box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
  text-align: left;

  height: 347px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(242, 242, 242, 1);
  border-radius: 5px;
}

/* for matsubsc */

.popup-boxMatsubspending{
  position: fixed;
  background: #00000050;
  width: 50%;
  height: 100vh;
  top: 40%;
  left: 23%;
  right: 23%;
  z-index: 11111;
}

.boxMatsubspending{
position: relative;
/* width: 70%; */
margin: 0 auto;
height: auto;
max-height: 74vh;
margin-top: calc(100vh - 85vh - 20px);
border-radius: 4px;
padding: 14px;
overflow: auto;
margin-top: 200px;
/* margin-left: 492px; */
background: inherit;
color: #FFF5EE;
background-color: rgb(147, 148, 148);
border: none;
border-radius: 5px;
-moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.34901960784313724);
-webkit-box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
text-align: left;

height: 347px;
box-sizing: border-box;
border-width: 1px;
border-style: solid;
border-color: rgba(242, 242, 242, 1);
border-radius: 5px;
}
  
  .contactpop-txt{
    font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #391847;
    font-size: 16px;
  }
  .cont-verifytxt{
    font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 10px;
    /* color: #FFF5EE; */
    margin-left: -11px;
    letter-spacing: 0.5px;
  }
  .cont-verify-div{
    /* background-color: #B5924C; */
    margin-left: -15px;
    margin-right: -14px;
    overflow-x: hidden;

  }
  .cont-verifytext{
    /* font-family: 'Source Sans Pro Light', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 300;
    font-style: normal;
    font-size: 9px;
    /* color: #FFF5EE; */
    margin-left: 5px;
    margin-top: 5px;
    letter-spacing: 0.3px;
  }
  .cont-continfotext{
    /* font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    /* color: #B5924C; */
  }
  .cont-contactinfo-txt{
    /* font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 600;
    font-style: normal;
    font-size: 10px;
    /* color: #B5924C; */
    letter-spacing: 0.3px;
  }
  .cont-contactinfo-subtxt{
    /* font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 600;
    font-style: normal;
    font-size: 10px;
    color: #555555;
    letter-spacing: 0.3px;
  }