/* Popup style */
.popup-boxlastnamepop {
    position: fixed;
    background: #00000060;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  .boxlastnamepop {
    position: relative;
    width: 38%;
    margin: 0 auto;
    height: auto;
    max-height: 74vh;
    margin-top: calc(100vh - 85vh - 20px);
    border-radius: 4px;
    padding: 14px;
    overflow: auto;
    margin-top: 241px;
    margin-left: 500px;
    height: auto;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 5px;
    box-shadow: none;
    
  }
  
  .close-iconlastnamepop {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(46% - 30px);
    top: calc(126vh - 85vh - 33px);
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    font-size: 18px;
    color: #fff;
  }
  .topnewsrowlastspellpop{
    background-color: rgba(242, 242, 242, 1);
        box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 1);
    border-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #F2F2F2;
  margin-left: -15px;
  margin-right: -14px;
  margin-top: -15px;
  padding-top: 6px;
  padding-bottom: 6px;
  }
  .lastpop-top-text{
    font-family: 'Open Sans Semibold', 'Open Sans Regular', 'Open Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 13px;
    color: #555555;

}
.refermem-secondtop-text{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 10px;
    color: #F2F2F2;
}
.lastnamepoptop-text{
    font-family: 'Source Sans Pro Oblique', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: oblique;
    font-size: 13px;
}
.lastname-textpop:hover{
    color: #0500E6 !important;
    cursor: pointer;}
    .tooltip1{
        width: 350px !important;
        padding-left: 7px !important;
      padding-top: 3px !important;
      padding-bottom: 3px !important;
      font-family: 'Source Sans Pro Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
      font-weight: 400;
      font-style: italic;
      font-size: 13px;
      color: #F2F2F2;
      white-space: nowrap;
    }