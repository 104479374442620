.testimonial .slick-arrow.slick-prev,
.testimonial .slick-arrow.slick-next {
  width: 60px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 5px;
  color: black;
}
.testimonial .slick-prev::before,
.testimonial .slick-next::before {
  display: none;
}
.testimonial .slick-arrow.slick-next {
  right: -70px;
}
.testimonial .slick-arrow.slick-prev {
  left: -70px;
}
.testimonial .slick-dots li button:before {
  font-size: 10px;
  color: #404040 !important;
}
.testimonial .slick-dots li {
  top: 10px;
}
.testimonname{
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
color: rgb(75, 75, 75);
margin-left: -88px;
text-transform: capitalize;
}
.testnmaediv{
  margin-bottom: 20px !important;
  text-align: left;
  margin-left: 90px;
  

}
.discrip{
  font-size: 16px;
  color: rgb(27, 26, 26) !important;
}
.slider-location-name{
font-size: 12px;
text-transform: capitalize;

}
.slider-name{
font-size: 11px;
text-transform: capitalize;
color: #212529;
font-weight: 500;


}
.slider-div{
    background-color: rgba(248, 248, 255, 1);
    margin-top: 15px;
}
.bpositiv{
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
}
.sliderbaccolor{
    background-color: #ffffff;
}
.slick-dots {
    position: absolute;
    bottom: 7px !important;
}

/* .slide{

  transform: translateX(-50%);
  transition: all .5s ease;
} */