/* Popup style */
.popup-boxinfo {
  position: relative;
  width: 15%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;

  }
  
  .boxinfo {
    position: relative;
    width: 15%;
    margin: 0 auto;
    height: auto;
    max-height: 33vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 5px;
    padding: 7px;
    padding-bottom: 8px;
    border: 1px solid #999;
    overflow: auto;
    margin-top: 179px;
    margin-left: 1085px;
    background-color: rgb(0, 191, 191);
    color: #fff;
    line-height: 1.2;
  }
  
  .close-iconinfo {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(45% - 30px);
    top: calc(140vh - 85vh - 33px);
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    font-size: 18px;
  }
  .inform-spn{
    font-family: 'Source Sans Pro Italic', 'Source Sans Pro', sans-serif !important;
    font-weight: 400;
    font-style: italic;
    font-size: 14px;
    color: white;
    text-align: center;
  }
  .informok{
color: rgb(0, 191, 191);
background-color: white;
border: 1px solid white;
border-radius: 3px;
height: 23px;
width: 48px;
font-size: 14px;
  }