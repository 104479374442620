.popup-boxSign {
    position: fixed;
    background: #00000060;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 111;
  }
  
  .boxSign {
    position: relative;
    width: 53%;
    margin: 0 auto;
    height: auto;
    /* max-height: 70vh; */
    margin-top: calc(100vh - 85vh - 20px);
    border-radius: 4px;
    /* border-color: rgb(192, 192, 192); */
border-color: rgba(181, 146, 76, 1);
    /* padding: 14px; */
    padding-bottom: 5px !important;
    overflow: auto;
    margin-top: 60px;
    margin-left: 358px;
    padding-top: 0px !important;
    padding-left: 0px !important;


    /* height: 385px; */
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 8px;
    border-style: solid;
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    

  }
  
  .close-iconSign {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(25% - 37px);
    background: #ededed;
    border: 1px solid #999;
    border-radius: 50%;

    top: calc(140vh - 129vh - 37px);
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    font-size: 16px;
  }

  
.margingleftrightminus{
  margin-right: 0px !important;
  margin-left: 0px !important;

}
  .demo1 .MuiStepper-root {
  /* width: 797px !important;
  margin-left: -15px !important;
  margin-right: -12px !important; */
  margin-top: -5px;
  border-radius: 0px !important;
  }