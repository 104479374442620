.border2px{
    border: 2px solid;
    border-color: rgba(181, 146, 76, 1);
    border-radius: 3%;
}
.txtaligncenter{
    text-align: center;
}
.namespan{
    color: #F3F3F3;
    font-size:24px;
    font-style: italic;
    font-family: 'playfair';
}
.spantxt{
    color: #F3F3F3;
    font-size:15px;;
}
 .bgimg{
    background-image: url('../../images/Bachground image.png');
    background-position: center;
    /* background-size: 90%; */
    width: 380px; /* Set width as needed */
    height: 480px;
    background-repeat: no-repeat; /* Prevents tiling */
    background-size: contain;
    padding-top: 20px !important;

} 
.matrimonyborfercloser{
    border: 1px solid #c0a56e !important;
    background-color: #fffbe8 !important;
    padding-top: 4px;
    padding-bottom: 6px;
}