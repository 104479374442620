.popup-boxmessageexpand {
    position: fixed;
     background: #00000050; 
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 11111;
  }
  
  .boxmessageexpand {
    position: relative;
    width: 22%;
    margin: 0 auto;
    /* height: 560px; */
    height: auto;
    /* max-height: 61vh; */
    margin-top: calc(100vh - 87vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 0px;
    padding-top: 0px;
    border: 1px solid #999;
    overflow: auto;
    /* margin-top: 266px; */
    /* margin-left: 754px; */
    float: right;
    margin-right: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }