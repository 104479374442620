/* Popup style */
.popup-boxcheckdetail {
    position: fixed;
    background: #00000060;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  .boxcheckdetail {
    position: relative;
    width: 39%;
    margin: 0 auto;
    height: auto;
    max-height: 74vh;
    margin-top: calc(100vh - 85vh - 20px);
    border-radius: 4px;
    padding: 14px;
    overflow: auto;
    padding-bottom: 0px !important;
    margin-top: 244px;
    margin-left: 392px;


    /* height: 320px; */
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    
  }
  
  .close-iconrefmember {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(46% - 30px);
    top: calc(126vh - 85vh - 33px);
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    font-size: 18px;
    color: #fff;
  }
  .topnewsrow{
    /* background-color: #214B0D; */
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(218, 216, 216);
    border-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    /* font-family: 'Source Sans Pro', sans-serif; */
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    /* color: #F2F2F2; */
  margin-left: -15px;
  margin-right: -14px;
  margin-top: -15px;
  padding-top: 11px;
  padding-bottom: 10px;
  }
  .refermem-top-text{
    font-family: 'Open Sans Semibold', 'Open Sans Regular', 'Open Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #B5924C;
    font-size: 13px;

}
.refermem-secondtop-text{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 10px;
    color: #F2F2F2;
}
.matchesbloogrp-text{
    font-family: 'Source Sans Pro Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 13px;
    color: #B5924C;
  }
  .redinesstext{
    font-family: 'Source Sans Pro Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 13px;
    color: #7F7F7F;
  }
  .bloodreqmyreq-text{
    font-family: 'Source Sans Pro Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 13px;
    color: #F2F2F2;
  }