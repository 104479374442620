.registerspn{
    font-family: "Merriweather Bold", "Merriweather Regular", Merriweather, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    color: rgb(238, 118, 0);
}

.MuiStepLabel-iconContainer {
    display: flex;
    flex-shrink: 0;
    padding-right: 8px;
  }
  .MuiStepIcon-root.MuiStepIcon-active {
    color: rgba(181, 146, 76, 1)!important;
    margin-top: -6px !important;
  
    
  }
  .MuiStepIcon-root.MuiStepIcon-completed {
    color: rgba(181, 146, 76, 1) !important;
    margin-top: -6px !important;
  
  }
  .MuiStepIcon-root{
    width: 1.4em !important;
    border-radius: 0px !important;
    color: #F2F2F2 !important;
  }
   .MuiSvgIcon-root {
    fill: currentColor;
    width: 1.4em !important;
    height: 1.4em !important;
    display: inline-block;
    font-size: 1.4rem !important;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
      margin-top: -6px !important;
  
  } 
  .MuiStepLabel-label.MuiStepLabel-active {
    color: rgba(181, 146, 76, 1) !important;
    font-weight: 500;
}
.MuiStepLabel-label.MuiStepLabel-active.MuiStepIcon-text {
    font-weight: 500;
    color: #F2F2F2 !important;

}
.MuiStepLabel-label.MuiStepLabel-completed {
    color: rgba(181, 146, 76, 1) !important;
    font-weight: 500;
}
.MuiStepper-root {
    display: flex;
    padding: 13px;
    margin-left: -15px;
    margin-right: -15px;
    /* width: 1024px !important; */
    background-color:#214B0D !important ;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;

}
.MuiStepLabel-label {
    color: #F2F2F2!important;
}
.MuiStepIcon-text{
    fill: black !important;
}
.MuiStepIcon-text.MuiStepIcon-active{
    fill: #F2F2F2 !important;
}
.MuiStepIcon-text.MuiStepIcon-completed{
    fill: #F2F2F2 !important;
}
.form-container{
    /* background-color: rgba(242, 242, 242, 1); */
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    background-color: rgba(255, 255, 255, 1);
    margin-left: 15px;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
}
.form-container1{
    /* background-color: rgba(242, 242, 242, 1); */
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    background-color: rgba(255, 255, 255, 1);
    margin-left: 15px;
    margin-bottom: 10px;
    padding-bottom: 60px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
}
.form-container2{
    /* background-color: rgba(242, 242, 242, 1); */
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    background-color: rgba(255, 255, 255, 1);
    margin-left: 15px;
    margin-bottom: 10px;
    padding-bottom: 25px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
}
.form-containerwhite{
    width: 738px;
    height: 548px;
    background: inherit;
    /* background-color: rgba(255, 255, 255, 1); */
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 1);
    border-radius: 5px;
    -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.34901960784313724);
    -webkit-box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
    box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
    margin-bottom: 15px;
    padding-bottom: 10px;
    background-color: rgba(255, 255, 255, 1);
}
 
    .colorbackblue{
    background-color: rgba(242, 242, 242, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    margin-left: 15px;
    padding-bottom: 45px;
}
.backcolorsteper{
    background-color: rgba(242, 242, 242, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    margin-left: 15px;
}
.lbl{
font-weight: 600;
font-style: normal;
color: #555555;
text-align: right;
font-size: 13px;
}
.question{
    /* font-family: 'Source Sans Pro Light Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 400;
    font-style: italic;
    font-size: 12px;
    color: #676666;
    white-space: nowrap;
    text-align: left;
    margin-left: -15px;
}
.textbox{
    width: 129px !important;
    height: 36px !important;
}
.textboxselect{
    height: 36px !important;
}
.textboxselect1{
    height: 36px !important;
    width: 43px;
}
.displynoneprev{
    display: none !important;
}
.prevbtn{
    left: 0px;
    top: 0px;
    width: 160px;
    height: 48px;
    background: inherit;
    background-color: rgba(254, 253, 250, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(181, 146, 76);

    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: rgb(181, 146, 76);
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
}
.prevbtn:hover{
    -moz-box-shadow-bottom: 3px 3px 3px rgba(181, 146, 76, 1);
-webkit-box-shadow-bottom: 3px 3px 3px rgba(181, 146, 76, 1);

 box-shadow: 3px 3px 3px rgba(181, 146, 76, 1);
letter-spacing: 1px;
}
.nextbtn{
    
    width: 220px;
    height: 50px;
    font-size: 18px;
    background: linear-gradient(180deg, #214B0D 9%, #214B0D 51%);
    border: none;
    border-radius: 5px;
    -moz-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.34901960784313724);
    -webkit-box-shadow: 3px 3px 3px rgb(0 0 0 / 35%);
    box-shadow: 3px 3px 3px rgb(0 0 0 / 35%);
    font-size: 16px;
    position: absolute;
color: #F2F2F2;
margin-left: -152px;
font-weight: bold;
letter-spacing: 0.3px;

}
.nextbtn:hover{
box-shadow: 3px 3px 3px gray;
letter-spacing: 1px;
}
.nextbtn1{
    width: 160px;
    height: 48px;
    font-size: 18px;
    background: linear-gradient(180deg, #214B0D 9%, #214B0D 51%);
    border: none;
    border-radius: 5px;
    -moz-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.34901960784313724);
    -webkit-box-shadow: 3px 3px 3px rgb(0 0 0 / 35%);
    box-shadow: 3px 3px 3px rgb(0 0 0 / 35%);
    font-size: 16px;
    position: absolute;
    color: #F2F2F2;
    font-weight: bold;
    letter-spacing: 0.3px;


}
.nextbtn1:hover{
    box-shadow: 3px 3px 3px gray;
    letter-spacing: 1px;
}
.nextbtn2{
    width: 270px;
    height: 48px;
    font-size: 15px;
    background: linear-gradient(180deg, #214B0D 9%, #214B0D 51%);
    border: none;
    border-radius: 5px;
    -moz-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.34901960784313724);
    -webkit-box-shadow: 3px 3px 3px rgb(0 0 0 / 35%);
    box-shadow: 3px 3px 3px rgb(0 0 0 / 35%);
    font-size: 16px;
    position: absolute;
    font-weight: bold;
color: #F2F2F2;
letter-spacing: 0.3px;

}
.nextbtn2:hover{
    box-shadow: 3px 3px 3px gray;
    letter-spacing: 1px;
   
}
.alreadyspn{
    /* font-family: 'Source Sans Pro SemiBold Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 700;
    font-style: italic;
    font-size: 11px;
    color: #555555;
}
.alreadyspn1{
    /* font-family: 'Source Sans Pro SemiBold Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 700;
    font-style: italic;
    font-size: 11px;
    color: #555555;
}
.alreadyspn1:hover{
    /* font-family: 'Source Sans Pro SemiBold Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 700;
    font-style: italic;
    font-size: 11px;
    color: #214B0D;
}
.signin-stepper{
    /* font-family: 'Source Sans Pro SemiBold Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 700;
    font-style: italic;
    font-size: 11px;
    color: #391847;
    margin-left: 15px !important;
    padding: 4px;

}
.signin-stepper:hover{
    /* font-family: 'Source Sans Pro SemiBold Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 700;
    font-style: italic;
    font-size: 11px;
    color: #F2F2F2;
    background-color: #391847;
    padding: 4px;

    margin-left: 15px !important;
}
.enterotptxt{
    /* font-family: 'Source Sans Pro Bold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 700;
    color: #555555;
    font-size: 13px;
text-align: center;

}
.nri-text{
    /* font-family: 'Source Sans Pro SemiBold Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 500;
    font-style: italic;
    color: #555555;
    font-size: 13px;
    text-align: center;
    margin-left: -58px;
}
.otpbox{
    width: 67px;
    height: 67px;
    border: 1px solid #FF8427 ;
    border-radius: 5px!important;

}
.otpbox1{
    width: 67px;
    height: 67px;
    border: 1px solid #FF8427 ;
    margin-left: -13px;
    border-radius: 5px!important;

}
.otpbox3{
    width: 67px;
    height: 67px;
    border: 1px solid #FF8427 ;
    margin-left: -27px;
    border-radius: 5px!important;
}
.otpbox4{
    width: 67px;
    height: 67px;
    border: 1px solid #FF8427 ;
    margin-left: -41px;
    border-radius: 5px!important;
}
.dindopt-txt{
    /* font-family: 'Source Sans Pro Light', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 300;
    font-style: normal;
    padding-left: 3px;
    padding-right: 3px;
    font-size: 13px;
}
.dindopt-txt1:hover{
    /* font-family: 'Source Sans Pro Light', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 300;
    font-style: normal;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #676666;

}
.resend-text{
    color: #FF8427;
    /* font-family: 'Source Sans Pro Light', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 300;
    font-style: normal;
  padding-left: 3px;
  padding-right: 3px;
  cursor: pointer;
  font-size: 13px;
}
.resendtxt:hover{
    color: white;
    /* font-family: 'Source Sans Pro Light', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 300;
    font-style: normal;
    background-color: #FF8427;
    border-radius: 3px;
    padding-left: 3px;
    padding-right: 3px;
    cursor: pointer;
    font-size: 13px;

}
.membership-text{
    /* font-family: 'Source Sans Pro Bold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 700;
    font-style: normal;
    font-size: 23px;
    color: #B5924C;
}
.member-name{
    /* font-family: 'Merriweather Black', 'Merriweather Regular', 'Merriweather', sans-serif; */
    font-weight: 900;
    font-style: normal;
    font-size: 17px;
    color: #391847;
}
.subscrip-div{
    top: 0px;
    /* width: 742px; */
    height: 231px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(181, 146, 76, 1);
    border-radius: 10px;
    -moz-box-shadow: 0px 5px 5px 0px rgba(242, 242, 242, 1) inset;
    -webkit-box-shadow: 0px 5px 5px 0px rgb(242 242 242) inset;
    box-shadow: 0px 5px 5px 0px rgb(242 242 242) inset;
    /* margin-left: 110px; */
    padding-top: 0px;

}
.subscripdiv-text{
    /* font-family: 'Source Sans Pro Bold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 700;
    color: #555555;
    font-size: 15px;
}
.add-now{
    /* font-family: 'Source Sans Pro Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 400;
    font-style: italic;
    font-size: 13px;
    color: #FF8427;

}
.add-now:hover{
    /* font-family: 'Source Sans Pro Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 400;
    font-style: italic;
    font-size: 13px;
    background-color: #FF8427;
    color: #F2F2F2;
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 3px;

}
.have-discount{
    /* font-family: 'Source Sans Pro Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 400;
    font-style: italic;
    font-size: 13px;
    color: #555555;

}
.chekboxlbl{
    /* font-family: 'Source Sans Pro', sans-serif; */
    font-weight: 400;
    font-style: normal;
    font-size: 11px;
    color: #555555;
    text-align: center;
}
.chekboxlblcomp{
    /* font-family: 'Source Sans Pro Italic', 'Source Sans Pro', sans-serif; */
    font-weight: 400;
    font-style: italic;
    font-size: 13px;
    color: #7F7F7F;
}
#radiusdivchek input[type=checkbox]{
    border-radius: 0px !important;
    width: 14px;
    height: 15px;
    margin-top: 6px;

}

.chek{
    background-color: #FF8427 !important;
}
.MuiTypography-body2 {
    /* font-family: 'Source Sans Pro SemiBold Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif !important; */
    font-weight: 400 !important;
    font-style: italic !important;
    font-size: 12px !important;
}
.case1{
    cursor: pointer;
    padding: 2px 9px 2px 9px;
    background: #EEE none repeat scroll 0% 0%;
    border: 1px solid #AAA;
    border-radius: 3px;
    color: #555555 !important;
    text-decoration: none;
}
.case1:hover{
    cursor: pointer;
    padding: 2px 9px 2px 9px;
    background: #EEE none repeat scroll 0% 0%;
    border: 1px solid #AAA;
    border-radius: 3px;
    color: #555555 !important;
    text-decoration: none !important;
}
.case2:hover{
    cursor: pointer;
    padding: 2px 9px 2px 9px;
    background: #EEE none repeat scroll 0% 0%;
    border: 1px solid #AAA;
    border-radius: 3px;
    color: #555555 !important;
    text-decoration: none !important;
}
.case2{
    cursor: pointer;
    padding: 2px 9px 2px 9px;
    background: #EEE none repeat scroll 0% 0%;
    border: 1px solid #AAA;
    border-radius: 3px;
    color: #555555 !important;
    text-decoration: none;
}
.succimg{
    margin-top: 128px;
    margin-left: -36px;
}
.succimg1{
    margin-top: 128px;
    margin-left: -44px;
}
.rslable{
    left: 0px;
    top: 0px;
    width: 91px;
    height: 34px;
    background-image: inherit;
    background-position: inherit;
    background-size: inherit;
    background-repeat: inherit;
    background-attachment: inherit;
    background-origin: inherit;
    background-clip: inherit;
    background-color: rgb(0, 191, 191);
    border: none;
    border-radius: 5px;
    box-shadow: none;
    font-size: 17px;
    color: rgb(242, 242, 242);
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    /* font-family: 'Source Sans Pro Bold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 700;

}
.verifycaptcha{
    background-color: rgb(0, 191, 191);
    border: none;
    border-radius: 5px;
    box-shadow: none;
    font-size: 15px;
    color: rgb(242, 242, 242);
    padding: 5px;

}
.paidsucc-text{
    font-style: normal;
    font-size: 17px;
    color: rgb(181, 146, 76);
    text-align: right;
    /* font-family: 'Source Sans Pro Bold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 700;
}
.paidfail-text{
    /* font-family: "Source Sans Pro Bold", "Source Sans Pro Regular", "Source Sans Pro", sans-serif; */
    font-weight: 700;
    font-style: normal;
    font-size: 17px;
    color: rgb(217, 0, 27);
}
.recivemail-text{
    /* font-family: "Source Sans Pro Italic", "Source Sans Pro Regular", "Source Sans Pro", sans-serif; */
    font-weight: 400;
    font-style: italic;
    color: rgb(85, 85, 85);
    text-align: center;
}
.transactionid{
    font-style: normal;
    font-size: 15px;
    color: #555555;
    /* font-family: 'Source Sans Pro Bold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 700;
}
.compl-propilebtn{
    width: 200px;
    height: 48px;
    font-size: 18px;
    background: linear-gradient(180deg, #214B0D 9%, #214B0D 51%);
    border: none;
    border-radius: 5px;
    -moz-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.34901960784313724);
    -webkit-box-shadow: 3px 3px 3px rgb(0 0 0 / 35%);
    box-shadow: 3px 3px 3px rgb(0 0 0 / 35%);
    font-size: 16px;
    position: absolute;
    font-weight: bold;
color: #F2F2F2;
letter-spacing: 0.3px;
}

.complet-name{
    /* font-family: 'Source Sans Pro', sans-serif; */
    font-weight: 400;
    font-style: normal;
    color: #F2F2F2;
    text-align: center;
    background-color: rgb(181, 146, 76);
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 0px;
    padding-bottom: 2px;
}

.adreservdate{
    border: 1px solid lightgray;
    padding-left: 9px;
    padding-top: 1px;
    padding-bottom: 1px;
    height: 37px;
    border-radius: 5px;
    font-size: 12px !important;
    width: 255px;
    color: #495057 !important;
}
.datwidth{
    width: 375px !important;

}
.adreservdate11{
    border: 1px solid lightgray;
    padding-left: 9px;
    padding-top: 1px;
    padding-bottom: 1px;
    height: 37px;
    border-radius: 5px;
    font-size: 12px !important;

    width: 381px;
    color: #495057 !important;
}


.gendertxt{
    font-size: 13px;
    color: #555555;
    text-align: left;
    line-height: normal;
    /* font-family: 'Source Sans Pro', sans-serif; */
    font-family: 'Poppins', sans-serif;

    font-weight: 400;
    font-style: normal;
}
.gendertxt:hover{
    font-size: 13px;
    color: rgb(181, 146, 76);
    text-align: left;
    line-height: normal;
    /* font-family: 'Source Sans Pro', sans-serif; */
    font-family: 'Poppins', sans-serif;

    font-weight: 400;
    font-style: normal;
}
#defaultRadio:hover{
color: #214B0D !important;
accent-color: #214B0D !important;

}
#defaultRadio{
    accent-color: #214B0D !important;
    }
 #defaultRadio1:hover{
    color: #214B0D !important;
    accent-color: #214B0D !important;
        
}
#defaultRadio1{
     accent-color: #214B0D !important;
    }
#defaultRadio2:hover{
    color: #214B0D !important;
            accent-color: #214B0D !important;
            
}
#defaultRadio2{
    accent-color: #214B0D !important;
}
#defaultRadio55{
    accent-color: #FF8427 !important;
    }
    #defaultRadio55:hover{
        color: #FF8427 !important;
        accent-color: #FF8427 !important;
                
    }
    #defaultRadio55{
        accent-color: #FF8427 !important;
        }
    #defaultRadio55:hover{
            color: #FF8427 !important;
            accent-color: #FF8427 !important;
                    
    }
    #defaultRadio4{
        accent-color: #214B0D !important;
        }
        #defaultRadio5:hover{
            color: #214B0D !important;
            accent-color: #214B0D !important;
                    
    }
    #defaultRadio3{
        accent-color: #214B0D !important;
        }
        #defaultRadio3:hover{
            color: #214B0D !important;
            accent-color: #214B0D !important;
                    
    }
    #defaultRadio5{
        accent-color: #214B0D !important;
    }  
     #defaultRadio6:hover{
    color: #214B0D !important;
    accent-color: #214B0D !important;
                    
    }
    #defaultRadio6{
        accent-color: #214B0D !important;
        }

        #compleprof input[type='radio']:after {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            top: -4px;
            left: -1px;
            position: relative;
            background-color:  #FF8427;
            content: '';
            display: inline-block;
            visibility: visible;
            border: 2px solid white;
            -moz-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.34901960784313724);
            -webkit-box-shadow: 2px 2px 2px rgb(0 0 0 / 35%);
            box-shadow: 2px 3px 2px rgb(0 0 0 / 35%);
        }
        
        #compleprof input[type='radio']:checked:after {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            top: -4px;
            left: -1px;
            position: relative;
            background-color: white;
            content: '';
            display: inline-block;
            visibility: visible;
            border: 3px solid #FF8427;
            -moz-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.34901960784313724);
            -webkit-box-shadow: 2px 2px 2px rgb(0 0 0 / 35%);
            box-shadow: 2px 3px 2px rgb(0 0 0 / 35%);
        }

        
        
        .blog-auth-text{
            font-family: 'Source Sans Pro Light', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
            font-weight: 300;
            font-style: normal;
            font-size: 10px;
            color: #F2F2F2;
            background-color: #B5924C;
            padding-left: 4px;
            padding-right: 4px;
            padding-top: 1px;
            padding-bottom: 2px;
        }

.whitespacenowrap{
    white-space: nowrap;
}


input[type="radio"]:checked:before {
    background: #214B0D !important;
    
}

input[type="radio"]:checked {
  border-color:#214B0D !important;
}
.contactmain-labl{
    font-family: 'Source Sans Pro Bold', 'Source Sans Pro', sans-serif;
    font-weight: bolder;
    font-style: normal;
    color: #333333;
    font-size: 16px;
}
.visibletext-spn{
    font-family: 'Source Sans Pro Italic', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 14px;
}
.borderbott{
    border-bottom: 1px solid rgb(220, 216, 216);
    /* width: 907px ;
    margin-left: -28px !important; */

}
.borderbott1{
    border-bottom: 1px solid rgb(220, 216, 216);
    /* width: 907px ;
    margin-left: -57px !important; */


}
.alreadyspn1comp{
    font-family: 'Source Sans Pro Italic', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 13px;
    color: #555555;
}
.infocircle{
    position: absolute;
    margin-left: 4px;
    margin-top: -3px;
}
.infocircle:hover{
    position: absolute;
    margin-left: 4px;
    margin-top: -3px;
    color: #FF8427 !important ;
}
.veryfyadardiv{
    border: 1px solid #214B0D;
    border-radius: 5px;
}
.step1{
    font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 13px;
    color: #555555;
}
.instantveritext{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: #555555;
}
.verifybtn{
    background-color: #214B0D;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: #214B0D;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #F2F2F2;
    width: 321px;
    margin-left: -16px;
    height: 45px;
}
.kycbtn{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #B5924C;
    border: none;
    background-color: rgba(242, 242, 242, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: none;
    width: 319px;
    margin-left: -15px;
    height: 45px;
}
.kycbtn:hover{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #F2F2F2;
    border: none;
    background-color: #214B0D;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: #214B0D;
    border-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 319px;
    margin-left: -15px;
    height: 45px;
}



.kycdiv{
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 4px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;

}
.kycdiv:hover{
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border: 1px solid #214B0D;
    border-radius: 4px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;

}
.important{
    background: inherit;
    background-color: rgba(0, 191, 191, 1);
    border: none;
    border-radius: 2px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #F2F2F2;
    padding-left: 5px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 4px;
}
.important-text{
    font-family: 'Source Sans Pro Italic', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 16px;
    color: #555555;
}
.securitycode{
    font-family: 'Axure Handwriting Bold', 'Axure Handwriting', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    text-decoration: line-through;
    
}
.backcolor{
    background-color: rgba(56, 202, 179, 1) !important;
    font-weight: bold;

}
.sucessokbtn{
border: 1px solid rgba(56, 202, 179, 1);
padding-left: 27px;
padding-top: 4px;
padding-bottom: 4px;
padding-right: 27px;
color: #555555;
border-radius: 5px;

}
.refrel-disc-code{
    font-family: 'Source Sans Pro Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 14px;
}
.removed-refcode-btn{
    font-family: 'Source Sans Pro SemiBold Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-style: italic;
    font-size: 14px;
    color: #D9001B;
}
.removed-refcode-btn:hover{
    font-family: 'Source Sans Pro SemiBold Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-style: italic;
    font-size: 14px;
    background: #D9001B;
    color: #F2F2F2;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 3px;
}
.workingtime {
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid #d0d3d3;
    margin-left: -1px !important;
}
.checkbox11 {
    /* transform: scale(1.5); */
    width: 4.5rem;
    height: 2rem;
    border-radius: 3px;
    border: 1px solid rgb(228, 227, 226);
    background: #e7e7e7;
    color: black;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-top: 0px;
    text-align: center !important;
    /* box-shadow: 0px 0.5px 1px 0px rgba(50, 50, 50, 0.72); */
}
.checkbox11--on {
    flex-direction: row;
    background: #c4c4c4;
    border: 1px solid rgb(250, 249, 249);
    color: black;
}
.checkbox__text {
    font-size: 13px;
    margin-left: 7px;
    margin-right: 10px;
}
.checkbox__ball {
    background: whitesmoke;
    /* box-shadow: 0px 3px 4px 0px rgba(50, 50, 50, 0.72); */
    width: 20px;
    height: 100%;
    border-radius: 3%;
}

.disabledayoff {
    /* z-index: -20; */
    cursor: not-allowed;
    opacity: 0.5;
    background: #CCC;
    pointer-events: none;
}