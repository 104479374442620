body{
    font-family: 'Poppins', sans-serif;
}
.row{
    margin-right: 0px !important;
    margin-left: 0px !important;

}
.marglrftrightminusrow{
    margin-right: -15px !important;
    margin-left: -15px !important;
}
.toprow{
background-color: #214B0D;
padding-top: 4px;
padding-bottom: 5px;
/* padding-right: 328px; */
}
.contactspn{
    font-size: 13px;
    color: #B5924C;
    font-weight: 400;
}
.straighline{
    font-size: 18px;
    color: #B5924C;
    font-weight: 400;
}
.whatsapp{
    color: #B5924C !important;

}
.logodiv{
    padding-top: 15px;
    padding-bottom: 15px;
}
.community-name{
    font-weight: 700;
    font-style: normal;
    color: #391847;
    font-size: 24px;
    letter-spacing: 0.2px;
position: absolute;
margin-top: 22px;
}
.joinowbtn{
    top: 22px;
    width: 160px;
    height: 40px;
    font-size: 18px;
    background: linear-gradient(180deg, rgba(238, 118, 0, 1) 9%, rgba(238, 118, 0, 1) 51%);
    border: none;
    border-radius: 5px;
    -moz-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.34901960784313724);
    -webkit-box-shadow: 3px 3px 3px rgb(0 0 0 / 35%);
    box-shadow: 3px 3px 3px rgb(0 0 0 / 35%);
    font-size: 18px;
    position: absolute;

}
.signin{
    border-width: 0px;
    position: absolute;
    top: 30px;
    width: 119px;
    height: 40px;
    display: flex;
    font-weight: 700;
    font-style: normal;
    color: #FF8427;
    font-size: 18px;
    letter-spacing: 0.2px;
    margin-left: -23px;
}
.kowmor{
    border-width: 0px;
    position: absolute;
    top: 30px;
    width: 119px;
    height: 40px;
    display: flex;
    font-weight: 700;
    font-style: normal;
    color: #FF8427;
    font-size: 16px;
    letter-spacing: 0.2px;
    margin-left: -23px;
    margin-top: 381px;
}
.backgimg{
    height: 70vh;
    width: 100%;
    background-image: url('../images/landimg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* transition: calc(); */

    animation-name: animate;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-play-state: running;
     animation-timing-function: 5s;

}
 @keyframes animate {
    0%{
        background-image: url('../images/landimg.png');
    }
    50%{
        background-image: url('../images/landimg2.png');
    }0%{
        background-image: url('../images/landimg3.png');
    }
}
.join-the-community{
    width: 200px;
    height: 38px;
    font-size: 18px;
    background: linear-gradient(180deg, rgba(238, 118, 0, 1) 9%, rgba(238, 118, 0, 1) 51%);
    border: none;
    border-radius: 5px;
    -moz-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.34901960784313724);
    -webkit-box-shadow: 3px 3px 3px rgb(0 0 0 / 35%);
    box-shadow: 3px 3px 3px rgb(0 0 0 / 35%);
    font-size: 16px;
    position: absolute;
    margin-top: 400px;
}
.section-name{
    font-weight: 700;
    font-style: normal;
    color: #391847;
    font-size: 24px;
    letter-spacing: 0.5px;
    text-align: center;
}
.cardrow{
    border: 1px solid;
    border-color: rgba(181, 146, 76, 1);
    margin-top: 20px;
    width: 340px;
border-radius: 5px;
}
.cardrow:hover{
    border: 1px solid;
    border-color: rgba(181, 146, 76, 1);
    margin-top: 20px;
border-radius: 5px;
width: 340px;

-moz-box-shadow-bottom: 2px 2px 5px rgba(181, 146, 76, 1);
-webkit-box-shadow-bottom: 2px 2px 5px rgba(181, 146, 76, 1);

 box-shadow: 2px 2px 5px rgba(181, 146, 76, 1);
}


.cardrow-blue{
    border: 1px solid;
    border-color: #214B0D;;
    margin-top: 20px;
border-radius: 5px;
width: 340px;

}
.cardrow-blue:hover{
    border: 1px solid;
    border-color: #214B0D;;
    margin-top: 20px;
border-radius: 5px;
width: 340px;

-moz-box-shadow-bottom: 2px 2px 5px #214B0D;
-webkit-box-shadow-bottom: 2px 2px 5px #214B0D;

 box-shadow: 2px 2px 5px #214B0D;
}

.cardimg-div {
    width: 54px !important;
    height: 60px;
    padding-left: 13px;
    position: absolute;
     background: #F8F8FF ;
    border: 1px solid rgba(181, 146, 76, 1);
margin-top: 43px;
    -webkit-transform: rotate(-41deg);
    -moz-transform: rotate(-41deg);
    -ms-transform: rotate(-41deg);
    -o-transform: rotate(-41deg);
    transform: rotate(-41deg);
    /* Rotate Origin */
    -webkit-transform-origin: 0 100%;
    -moz-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    -o-transform-origin: 0 100%;
    transform-origin: 0 100%;
    border: 1px solid rgba(181, 146, 76, 1);
    
  }
  .img-border{
    width: 49px;
    height: 53px;
    padding-left: 5px;
    margin-top: 3px;
    margin-left: -12px;
    background: rgba(181, 146, 76, 1);
    /* Rotate */
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    /* Rotate Origin */
    -webkit-transform-origin: 0 100%;
    -moz-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    -o-transform-origin: 0 100%;
    transform-origin: 0 100%;
  }

  .cardimg-div-blue {
    width: 54px !important;
    height: 60px;
    padding-left: 13px;
    position: absolute;
    background: #F8F8FF;
    border: 1px solid #214B0D;
    margin-top: 43px;
    transform-origin: 0 100%;

    /* Rotate */
    -webkit-transform: rotate(-41deg);
    -moz-transform: rotate(-41deg);
    -ms-transform: rotate(-41deg);
    -o-transform: rotate(-41deg);
    transform: rotate(-41deg);
    /* Rotate Origin */
    -webkit-transform-origin: 0 100%;
    -moz-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    -o-transform-origin: 0 100%;
    transform-origin: 0 100%;
    
  }
  .img-border-blue{
    width: 49px;
    height: 53px;
    padding-left: 5px;
    margin-top: 3px;
    margin-left: -12px;
    background: #214B0D;
    /* Rotate */
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    /* Rotate Origin */
    -webkit-transform-origin: 0 100%;
    -moz-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    -o-transform-origin: 0 100%;
    transform-origin: 0 100%;
  }

  .borderbottom{
    border-bottom: 2px solid rgba(181, 146, 76, 1);
  }
  
.card-img{
    margin-top: 9px;
    margin-left: 6px;
    width: 70% !important;
    -webkit-transform: rotate(42deg);
    -moz-transform: rotate(42deg);
    -ms-transform: rotate(42deg);
    -o-transform: rotate(42deg);
    transform: rotate(42deg);
}
.whitecolor{
    color: rgb(233, 231, 231) !important;
    width: 32px !important;
}

.cart-text{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #555555;
    line-height: 1 !important;
    font-size: 16px !important;
}
.card-title{
    font-weight: 600;
    font-style: normal;
    color: #FF8427;
    margin-top: 10px;
}

.xyz-community{
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    color: #391847;
}
.community-txt{
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: #555555;
}
.paddingleft{
    padding-right: 75px !important;
}
.know-more{
    top: 22px;
    width: 150px;
    height: 40px;
    font-size: 18px;
    background: linear-gradient(180deg, rgba(238, 118, 0, 1) 9%, rgba(238, 118, 0, 1) 51%);
    border: none;
    border-radius: 5px;
    -moz-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.34901960784313724);
    -webkit-box-shadow: 3px 3px 3px rgb(0 0 0 / 35%);
    box-shadow: 3px 3px 3px rgb(0 0 0 / 35%);
    font-size: 16px;
    font-weight: 700;
}
.googleplay-div{
    background-image: url('../images/Android\ App.png');
    height: 58vh;
    width: 100%;
    /* background-image: url('../images/landimg.png'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.downloadapptxt{
    font-weight: 700;
    font-style: normal;
    color: #FEFDFA;
    font-size: 24px;
}
    
.downloadnow{
    top: 22px;
    width: 170px;
    height: 40px;
    background: linear-gradient(180deg, #214B0D 9%, #214B0D 51%);
    border: none;
    border-radius: 5px;
    -moz-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.34901960784313724);
    -webkit-box-shadow: 3px 3px 3px rgb(0 0 0 / 35%);
    box-shadow: 3px 3px 3px rgb(0 0 0 / 35%);
    font-weight: 700;
}
.news-title{
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    color: #FF8427;
}
.newscard{
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    /* max-width: 29% !important; */
}
.newscard:hover{
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    /* max-width: 29% !important; */
-moz-box-shadow-bottom: 4px 4px 4px rgb(126, 124, 124);
-webkit-box-shadow-bottom: 4px 4px 4px rgb(126, 124, 124);

 box-shadow: 2px 2px 2px rgb(126, 124, 124);
}

.news-sub-title{
    font-weight: 700;
    font-style: normal;
    color: #555555;
    font-size: 16px;
}
.news-text{
    font-weight: 400;
    font-style: normal;
    color: #555555;
}
.news-readmor{
    /* font-family: 'Source Sans Pro Light', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #FF8427;
    font-size: 16px;
}
.arrowright{
    color: #FF8427;
    margin-left: 7px;

}
.news1-img{
    margin-left: -15px;
    width: 430px !important;
    height: 229px;
}
.news2-img{
    margin-left: -15px;
    width: 430px !important;
    height: 229px;
}
.news3-img{
    margin-left: -15px;
    width: 429px !important;
    height: 229px;
}
.sponsered{
    /* font-family: 'Source Sans Pro Light', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
     font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 13px;
    color: #391847;
    float: right !important;
}
.widhdiv{
    width: 206px !important;
}
.newsletterimg{
     background-image: url('../images/Newsletter.png'); 
    /* background-image: url('../images/Newsletter\ \(1\).png'); */

    height: 58vh;
    width: 100%;
    /* background-image: url('../images/landimg.png'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.communitygoogleplay-img{
    width:380px !important
}
.subscribenewsleterbtn{
    height: 40px;
    background: inherit;
    background-color: rgba(181, 146, 76, 1);
    border: none;
    border-radius: 5px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.34901960784313724);
    color: #FEFDFA;
    font-size: 16px;
    width: 158px;
}