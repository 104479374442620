/* Popup style */
.popup-boxrefmember {
    position: fixed;
    background: #00000030 !important;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 111;
  }
  .popup-boxreceipt {
    position: fixed;
    background: #00000030 !important;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 111;
  }
  .popup-boxrefmember1 {
    position: fixed;
    background: #00000020;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 111;
  }
  .popup-boxrefmember2 {
    position: fixed;
    background: #00000010;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 111;
  }
  .popup-boxrefmember11 {
    position: fixed;
    background: #00000060;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 111;
  }
  
  .boxrefmember {
    position: relative;
    width: 36%;
    margin: 0 auto;
    height: auto;
    max-height: 72vh;
    margin-top: calc(100vh - 85vh - 20px);
    border-radius: 4px;
    padding: 14px;
    padding-bottom: 0px !important;
    overflow: auto;
    margin-top: 264px;
    margin-left: 548px;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    
  }
  .boxReceipt {
    position: relative;
    width: 46%;
    margin: 0 auto;
    height: auto;
    max-height: 72vh;
    margin-top: calc(100vh - 85vh - 20px);
    border-radius: 4px;
    padding: 14px;
    padding-bottom: 0px !important;
    overflow: auto;
    margin-top: 264px;
    margin-left: 548px;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    
  }
  .boxrefmember1 {
    position: relative;
    width: 35%;
    margin: 0 auto;
    height: auto;
     max-height: 79vh; 
    margin-top: calc(100vh - 85vh - 20px);
    border-radius: 4px;
    padding: 14px;
    padding-bottom: 0px !important;
    overflow: auto;
     height: 445px !important; 
    margin-top: 144px;
    margin-left:396px;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    
  }
  .boxrecoffee {
    position: relative;
    width: 35%;
    margin: 0 auto;
    height: auto;
    /* max-height: 79vh; */
    margin-top: calc(100vh - 85vh - 20px);
    border-radius: 4px;
    padding: 14px;
    padding-bottom: 0px !important;
    overflow: auto;
     /* height: 445px !important;  */
    margin-top: 144px;
    margin-left:396px;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    
  }
  .boxrefmember11{
    position: relative;
    width: 35%;
    margin: 0 auto;
    height: auto;
    max-height: 60vh;
    margin-top: calc(100vh - 85vh - 20px);
    border-radius: 4px;
    padding: 14px;
    padding-bottom: 0px !important;
    overflow: auto;
    height: 345px !important;
    margin-top: 164px;
    margin-left:431px;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    
  }
  
  .close-commdirect {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(28% - 30px);
    background: #ededed;
    border: 1px solid #999;
    border-radius: 50%;
    top: calc(149vh - 126vh - 34px);
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    font-size: 16px;
    color: #555555;
  }
  .close-naidudi {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(32% - 33px);
    background: #ededed;
    border: 1px solid #999;
    border-radius: 50%;
    top: calc(149vh - 124vh - 17px);
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    font-size: 16px;
    color: #555555;
  }
  .close-iconrefmember {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(46% - 30px);
    top: calc(126vh - 85vh - 33px);
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    font-size: 18px;
    color: #fff;
  }
  .topnewsrow{
    /* background-color: #214B0D; */
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(218, 216, 216);
    border-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    /* font-family: 'Source Sans Pro', sans-serif; */
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    /* color: #F2F2F2; */
  margin-left: -15px;
  margin-right: -14px;
  margin-top: -15px;
  padding-top: 11px;
  padding-bottom: 10px;
  }
  .refermem-top-text{
    font-family: 'Open Sans Semibold', 'Open Sans Regular', 'Open Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #B5924C;
    font-size: 13px;

}
.refermem-secondtop-text{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 10px;
    color: #F2F2F2;
}
.unlock-text{
  font-size: 12px;
  color: #555555;
  text-align: center;
  font-weight: 600;
}
.nidudircancelbtn{
  width: 172px;
  height: 40px;
  background: inherit;
  background-color: rgba(254, 253, 250, 1);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(242, 242, 242, 1);
  border-radius: 5px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.2px;
  color: #555555;
  cursor: pointer;
}
.nidudircanNextbtn{
  width: 171px;
    height: 40px;
    background: inherit;
    background-color: rgba(238, 118, 0, 1);
    border: none;
    border-radius: 5px;
    -moz-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.34901960784313724);
    -webkit-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.34901960784313724);
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.34901960784313724);
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0.2px;
    cursor: pointer;
}
.downloadicon{
  background-color: #FF8427;
  border-radius: 50%;
  padding: auto;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 3px;
}