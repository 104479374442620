

.footer-main{
    background-color: #214B0D;
}
.footer-heading{
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    color: #FF8427;
}
.footer-text{
    font-weight: 400;
    font-style: normal;
    color: #FEFDFA;
    font-size: 16px;
    line-height: 1px !important; 
}
.bordertop{
    border-top: 2px solid #FF8427;
    padding-top: 5px;
    padding-bottom: 8px;

}
.allrightspn{
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    color: #FEFDFA;
    letter-spacing: 0.3px;
}
.paddingleft5{
padding-left: 25px !important;
}