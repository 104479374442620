/* Popup style */
.popup-boxnews {
    position: fixed;
    background: #00000060;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 111;
  }
  
  .boxnews {
    position: relative;
    width: 47%;
    margin: 0 auto;
    height: auto;
    max-height: 74vh;
    margin-top: calc(100vh - 85vh - 20px);
    border-radius: 4px;
    border-color: rgb(192, 192, 192);

    padding: 14px;
    padding-bottom: 0px !important;
    overflow: auto;
    margin-top: 240px;
    margin-left: 391px;


    height: 385px;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    

  }
  
  .close-iconnews {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(45% - 30px);
    top: calc(140vh - 85vh - 33px);
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    font-size: 16px;
  }
  .topnewsrow{
    /* background-color: #214B0D; */
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(218, 216, 216);
    border-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    /* font-family: 'Source Sans Pro', sans-serif; */
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    /* color: #F2F2F2; */
  margin-left: -15px;
  margin-right: -14px;
  margin-top: -15px;
  padding-top: 11px;
  padding-bottom: 10px;
  }
  .popupbtn-div{
    border-width: 1px;
    border-style: solid;
    border-color: rgb(218, 216, 216);
    border-radius: 5px;
     margin-left: -15px;
  margin-right: -14px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;


  }
  .radiobtn-text{
    /* font-family: 'Source Sans Pro Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 400;
    font-style: italic;
    color: #F2F2F2;
  }
  #radio1{
    /* accent-color: #FF8427 !important; */

  }
  
  

#sharediv input[type='radio']:after {
    width: 18px;
    height: 18px;
    border-radius: 15px;
    top: -4px;
    left: -2px;
    position: relative;
    /* background-color: #0040DD ; */
    content: '';
    display: inline-block;
    visibility: visible;
    /* border: 1px solid #0040DD; */
}

#sharediv input[type='radio']:checked:after {
    width: 18px;
    height: 18px;
    border-radius: 15px;
    top: -4px;
    left: -2px;
    position: relative;
    /* background-color: #0040DD; */
    content: '';
    display: inline-block;
    visibility: visible;
    /* border: 2px solid #0040DD; */
}

.sharan{
    /* font-family: 'Source Sans Pro', sans-serif !important; */
    font-weight: 400;
    font-style: normal;
    font-size: 13px !important;
    color: #F2F2F2 !important;
}
.rdiolble{
    /* font-family: 'Source Sans Pro Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif !important; */
    font-weight: 400;
    font-style: italic !important;
    color: #F2F2F2;
}
.adpicspn{
    /* font-family: 'Source Sans Pro Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 400;
    font-style: italic;
    font-size: 12px;
    color: #FF8427;
}
.inputwidth{
    width: 190px;
}
.cancelbtn{
    width: 160px;
    height: 48px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(181, 146, 76, 1);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    /* font-family: 'Source Sans Pro', sans-serif; */
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    letter-spacing: 0.2px;
    color: #B5924C;


}
.cancelbtn:hover{
    width: 160px;
    height: 48px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(181, 146, 76, 1);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    /* font-family: 'Source Sans Pro', sans-serif; */
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    letter-spacing: 1px;
    color: #B5924C;


}
.createeventbtn{
width: 160px;
    height: 48px;
    background: inherit;
    background-color: #214B0D;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: #214B0D;
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    /* font-family: 'Source Sans Pro Bold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.2px;
    color: #F2F2F2;
}
.createeventbtn:hover{
    width: 160px;
        height: 48px;
        background: inherit;
        background-color: #214B0D;
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        border-color: #214B0D;
        border-radius: 5px;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        /* font-family: 'Source Sans Pro Bold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
        font-weight: 700;
        font-style: normal;
        letter-spacing: 1px;
        color: #F2F2F2;
    }
     .uplod{
      border: none;
      border: 1px solid rgb(232, 230, 230);
      width: 215px;
      height: 159px;
      margin-top: -44px;
      background-color: white;
      content: url('../../images/imageupload.svg');  

    }
    .uplod:hover{
       /* background: linear-gradient(360deg, lightgrey 50%, transparent 50%);  */
            content: url('../../images/imageupload.svg');  

    }

    .uplodblog{
      border: none;
      border: 1px solid rgb(232, 230, 230);
      border: 1px solid rgb(232, 230, 230);
    width: 300px;
    height: 150px;
      background-color: white;
      content: url('../../images/imageupload.svg');  

    }
    .uplod:hover{
       /* background: linear-gradient(360deg, lightgrey 50%, transparent 50%);  */
            content: url('../../images/imageupload.svg');  

    }

    .photoupload-lbl{
      /* font-family: 'Source Sans Pro Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 400;
    font-style: italic;
    font-size: 11px;
    margin-top: -87px;
    margin-left:10px;
    position: absolute;
    }
    .radiobtn-textblog{
      /* font-family: 'Source Sans Pro Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 400;
    font-style: italic;
    font-size: 12px;
    color: #555555;

    }
    .kycinfo{
      margin-left: -18px;
      margin-top: 2px;
    }
    .kycimg-div{
      position: absolute;
      margin-top: -116px;
      margin-left: 62px;
    }
    .kycimg-divmat{
      margin-top: -116px;
      margin-left: 62px;
    }
    .tooltip{
      width: 234px ;
      padding-left: 7px !important;
      padding-top: 3px !important;
      padding-bottom: 3px !important;
      /* font-family: 'Source Sans Pro Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
      font-weight: 400;
      font-style: italic;
      font-size: 12px;
      color: #F2F2F2;
      white-space: nowrap;
      
    }
    .width{
      width: 270px !important;
    }
    .heightfordt{
      height: 27px !important;
      width: 150px !important;
    }
    .schedulforlbl{
      /* font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif; */
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    text-align: right;
    color: #555555;

    }
    .cpointer{
      cursor: pointer;
    }