.maintenance-container {
    text-align: center;
    padding: 20px;
    background-color: #ffffff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Arial', sans-serif;
    /* background-image: url('../images/background.jpg'); */
  background-size: cover; /* Make sure the image covers the entire page */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  position: relative; /* Ensure content overlays on top */
  }
  
  .logo img {
    width: 200px;
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 2.5em;
    color: #333;
  }
  
  p {
    font-size: 1.2em;
    color: #555;
    margin: 10px 0;
  }
  
  .countdown-timer {
    margin: 20px 0;
  }
  
  .timer {
    font-size: 2em;
    color: #d9534f;
    margin-top: 10px;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
    margin: 0 10px;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  .social-links {
    margin: 20px 0;
  }
  
  .social-links a {
    margin: 0 10px;
    font-size: 1.2em;
  }
  
  footer {
    margin-top: 40px;
    color: #888;
    font-size: 0.9em;
  }