/* Popup style */
.popup-boxnotify {
    position: fixed;
    background: #00000060;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 111;
    }
  
  .boxnotify {

    width: 35%;
    margin: 0 auto;
    height: auto;
    max-height: 109vh;
    margin-top: calc(100vh - 85vh - 20px);
    padding: 14px;
    overflow: auto;
    margin-top: 0px;
    margin-left: 1128px;
    background: inherit;
    /* background-color: rgba(246, 248, 248, 1); */
    background-color: #fff;
    border: none;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
    text-align: left;
    height: 707px;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    overflow-y: scroll;
    border-color: rgba(242, 242, 242, 1);
    -webkit-animation: slideIn 2s forwards;
    -moz-animation: slideIn 2s forwards;
    animation: slideIn 2s forwards; 
    position: relative;
    
  }
  @-webkit-keyframes slideIn {
    100% {
      transform: translateX(-133px);
    }
    0% {
      transform: translateX(0);
    }
  }
  @-moz-keyframes slideIn {
    100% {
      transform: translateX(-133px);
    }
    0% {
      transform: translateX(0);
    }
  }
  @keyframes slideIn {
    100% {
      transform: translateX(-133px);
    }
    0% {
      transform: translateX(0);
    }
  }
  
  
  .close-iconnotify {
    content: 'x';
    font-weight: bold;
    cursor: pointer;
    position: fixed;
    right: calc(11% - 30px);
    top: calc(140vh - 133vh - 33px);
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    font-size: 18px;
    color: #391847;
  }
  .notifytop-div{
    height: 93px;
    background: inherit;
    background-color: rgba(242, 242, 242, 1);
    border: none;
    border-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-left: -14px;
    margin-right: -14px;
    margin-top: -14px;
  }
  .drpdownnotifypopup{
    font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    letter-spacing: normal;
    color: #F2F2F2;
    vertical-align: none;
    text-align: left;
    text-transform: none;
    background-color: #B5924C;
    letter-spacing: 0.3px;
  }
  .colorlightorg{
    color: #B5924C;
  }
  .tooltipclosenotify{
    width: 130px ;
    padding-left: 7px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    font-family: 'Source Sans Pro Italic', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 10px;
    color: #555555 !important;
    background-color: transparent !important;
    margin-left: 55px !important;
    margin-top: 25px!important;
    white-space: nowrap;
  }
  
  .ViewJobcntactpop{
    background-color: #fff !important;
    border-radius: 3px !important;
    padding: 7px;
  }
  .hoverlightgray:hover{
background-color: #F2F2F2 !important;
  }
  .lightgraycard{
    background-color: #F2F2F2;
  }