/* Popup style */
.popup-boxupdateprof {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 111;
  }
  
  .boxupdateprof {
    position: relative;
    width: 40%;
    margin: 0 auto;
    height: auto;
    max-height: 74vh;
    margin-top: calc(100vh - 85vh - 20px);
    border-radius: 4px;
    padding: 14px;
    overflow: auto;
    margin-top: 184px;
    margin-left: 389px;

   
    height: auto;
     background-color: rgb(49, 49, 49); 
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(49, 49, 49);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #F2F2F2;
    
  }
  
  .close-boxupdateprof {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(46% - 30px);
    top: calc(126vh - 85vh - 33px);
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    font-size: 18px;
    color: #fff;
  }


  .popup-boxEditdpic {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 111;
  }
  
  .boxEditdpic {
    position: relative;
    width: 40%;
    margin: 0 auto;
    height: auto;
    max-height: 74vh;
    margin-top: calc(100vh - 85vh - 20px);
    border-radius: 4px;
    padding: 14px;
    overflow: auto;
    margin-top: 144px;
    margin-left: 382px;


    /* height: 320px; */
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    
    
  }
  
  .close-iconEditdpic {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(46% - 30px);
    top: calc(126vh - 85vh - 33px);
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    font-size: 18px;
    color: #fff;
  }

  .topnewsrow{
    /* background-color: #214B0D; */
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(218, 216, 216);
    border-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    /* font-family: 'Source Sans Pro', sans-serif; */
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    /* color: #F2F2F2; */
  margin-left: -15px;
  margin-right: -14px;
  margin-top: -15px;
  padding-top: 11px;
  padding-bottom: 10px;
  }
  .borderbottomforeditprof{
border-bottom: 1px solid #4d4c4c;
margin-left: -14px;
margin-right: -14px;

  }