/* .viewprofile-top-div{
    height: 40px;
    background: inherit;
    background-color: rgba(85, 85, 85, 1);
    border: none;
    border-radius: 3px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}
.tab-pane{
    position: fixed;

} */
.nav-item{
    /* color: #fff !important; */
}
.nav-link{
    color: #555555 !important;
/* border: 1px solid rgb(237, 237, 237) !important; */
border-top: none !important;
font-size: 12px !important;
border: none !important;
}
.horizontal-tabmenu{
    background-color: rgba(85, 85, 85, 1) !important;
color: #fff !important;
}
.nav-tabs .nav-link.active {
    color: #FF8427 !important;
    background-color: #fff !important;
    font-weight: 400;
    border: none !important;
    /* border-top: 1px solid rgb(237, 237, 237) !important; */

} 
.viewsub-activ-spn{
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #F2F2F2;
    background-color: rgba(4, 222, 113, 1);
    margin-left: 6px;
    /* padding: 3px; */
    border-radius: 3px;
    padding-left: 2px;
    padding-right: 3px;

}
.scrolltoviewsub-left-div{
    max-width: 20% !important;
    background-color: #fff !important;
    color: #555555 !important;
    height: 500px !important;
    overflow-y: scroll;
}
.navlinkforverticaltab{
    color: #F2F2F2 !important;

}
.nav-tabs .navlinkforverticaltab.active {
    background-color: #495057 !important;
    font-weight: 400;
    padding-bottom: 6px;

} 
.profile-hdtext{
    font-weight: 600;
    font-style: normal;
    font-size: 13px;
    color: #555555;
}
.border1px{
border: 1px solid rgb(237, 237, 237) !important;
border-radius: 5px !important;
}
.contactnvlink{
    background-color: #fff !important;
    color: #555555 !important;
    /* border-top: 1px solid rgb(240, 237, 237) !important; */
    /* border-right: 1px solid rgb(237, 237, 237) !important; */
    font-size: 12px !important;

}
.backcolor-none{
    background-color: #fff !important;
    border-bottom: none !important;
}
.colorblue{
    color: blueviolet !important;
}
.bordertop{
    border-top: 1px solid rgb(237, 237, 237) !important;
}
.borderorangeforevent{
    border:  1px solid #FF8427 !important;
    width: 100px !important;
}
.page-item,.page-link{
    color: #555555 !important;
    font-size: 12px !important;

}

.page-item.active .page-link{
    color: #fff !important;
    font-size: 12px !important;
}
.bluetextforlink{
    font-weight: 400;
    font-style: normal;
    font-size: 11px;
    color: #0500E6 !important;
    cursor: pointer;
}
.attendbtn{
    width: 130px;
    height: 40px;
    background-color: rgba(238, 118, 0, 1);
    border: none;
    border-radius: 5px;
    box-shadow: 3px 3px 3px rgb(0 0 0 / 35%);
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.2px;
    font-size: 12px;
    color: #fff;
}
.cardiv-coffee{
    height: auto;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 4px;
    /* -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.34901960784313724);
    -webkit-box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
    box-shadow: 5px 5px 5px rgb(0 0 0 / 35%); */
}
.showmorefor-coffee{
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: #555555;
}
.bloodcheckdetail{
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #0500E6;
}
.custfiltdiv{
    border: 1px solid lightgray;
    margin-bottom: -5px;
    border-radius: 4px;
    font-size: 13px;
    height: 37px;
  }
  .filterdiv{
    text-align: right;
  }
  .nowraptxt{
    white-space: nowrap;
    margin-top: 8px;
  
  }
  .nowraptxt{
    white-space: nowrap;
    margin-top: 8px;
  
  }
  .filtericon1{
    margin-top: 10px;
    margin-left: -3px;
    }
    /* for cust date filter */
    .popup-boxdate {
        position: fixed;
        background: #00000050;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 1;
      }
       
      .boxdate {
        position: relative;
        width: 25%;
        margin: 0 auto;
        height: auto;
        max-height: 79vh;
        margin-top: calc(100vh - 85vh - 20px);
        background: rgb(247, 245, 245);
        border-radius: 4px;
        padding: 20px;
        border: 1px solid #999;
        overflow: auto;
        margin-left: 587px;
        margin-top: 332px;
      }
       
      .close-icondate {
        content: 'x';
        cursor: pointer;
        position: fixed;
        /* right: calc(15% - 30px); */
        /* top: calc(100vh - 85vh - 33px); */
        background: #ededed;
        width: 25px;
      height: 25px;
      border-radius: 50%;
      line-height: 20px;
      text-align: center;
      border: 1px solid #999;
      font-size: 20px;
      margin-left: 338px;
        margin-top: -34px;
    }
    
    
    .dtspn{
     text-align: left !important;
     font-size: 12px ;
    }
    
    
    .datefiltcancel{
        background-color: white;
        color: #A47355;
        border: 1px solid;
        border-color: #A47355;
        width: 100px;
        height: 48px;
        border-radius: 5px;
        letter-spacing: 0.5px;
        margin-left: -10px;
    
      }
      .datefiltcancel:hover{
        cursor: pointer;
        background-color: #6B4C39;
          color: white;
        border: 1px solid;
        border-color: #A47355;
        width: 100px;
        height: 48px;
        border-radius: 5px;
        letter-spacing: 0.5px;
        margin-left: -10px;
    
      }
      .datefiltaply{
        background-color: #A47355;
        color: white !important;
        border: 1px solid;
        border-color: #ededed;
        width: 100px;
        border-radius: 5px;
        height: 48px;
        letter-spacing: 0.5px;
        margin-left: -5px;
    
    
      }
      .datefiltaply:hover{
        cursor: pointer;
        background-color: #6B4C39;
          color: white !important;
        border: 1px solid;
        border-color: #ededed;
        width: 100px;
        border-radius: 5px;
        height: 48px;
        letter-spacing: 0.5px;
        margin-left: -5px;
    
      }
      .fontsize14{
        font-size: 13px;
      }
      select.form-control:not([size]):not([multiple]) {
        /* height: calc(2.25rem + 2px); */
        font-size: 12px;
    }
    .fontcolorlight{
        color: rgb(120, 118, 118);
    }
    .filterdropdate11{
        width: 135px !important;
    }
    #showMe {
        animation: opacity 5s ease-in-out infinite;
    opacity: 1;
      }
      
      
@keyframes opacity {
    0% {
      opacity: 1;
    }
  
    50% {
      opacity: 0
    }
  
    100% {
      opacity: 1;
    }
  }

      .texthideshowautomatic{
        color: #D30F45;
        font-style: italic !important;
font-size: 13px;
      }

      .editpic-viewprof{
       
          font-weight: 400;
          font-style: normal;
          color: #555555;
          font-size: 11px;
          align-self: center;
          padding: 2px 2px 2px 2px;
          box-sizing: border-box;
          width: 100%;
          margin-top: -53px;
          margin-left: -10px;
          /* position: fixed; */
          text-align: center;
          position: relative;
          z-index: 999;

}
.editpic-viewprof1{
  font-weight: 400;
          font-style: normal;
          color: #555555;
          font-size: 11px;
          align-self: center;
          padding: 2px 2px 2px 2px;
          box-sizing: border-box;
          width: 100%;
          margin-top: -53px;
          margin-left: -30px;
          /* position: fixed; */
          text-align: center;
          position: relative;
          z-index: 999;
}

.imgforeditviewpic{
  border-width: 0px;
  width: 179px;
    height: 34px;
    margin-top: -59px;
    margin-left: -20px;
    position: relative;
} 
.imgforeditviewpic1{
  border-width: 0px;
    width: 177px;
    height: 34px;
    margin-top: -59px;
    margin-left: -10px;
    position: relative;
}     
.avtarimg{
  width: 140px !important;
  height: 121px !important;
  background-color: #FF8427 !important;
  color: #555555 !important;
}
.avtarimg1{
  width: 180px !important;
  height: 160px !important;
  background-color: #f8f5f5 !important;
  color: #555555 !important;
}
.avtarimgSm{
  width: 33px !important;
  height: 30px !important;
  background-color: #FF8427 !important;
  color: #555555 !important;
}
.avtarimgmd{
  width: 58px !important;
  height: 54px !important;
  background-color: #FF8427 !important;
  color: #555555 !important;
}

.playerProfilePic_home_tile{
  width: 180px !important;
  height: 175px !important;
  border: 1px solid lightgray;
  border-radius: 50%;

}
.playerProfilePic_home_tileblog{
  width: 180px !important;
  height: 165px !important;
  border: 1px solid lightgray;
}
.playerProfilePic_home_tile1{
  width: 212px !important;
    height: 205px !important;
    border: 1px solid lightgray;
    border-radius: 50%;
}
.playerProfilePic_home_tile2{
  width: 120px !important;
    height: 114px !important;
    border: 1px solid lightgray;

}
.playerProfilePic_home_tilebuss{
  width: 98px !important;
    height: 114px !important;
    border: 1px solid lightgray;

}
.playerProfilePic_home_tilebussprof{
  width: 182px !important;
    height: 200px !important;
    border: 1px solid lightgray;
}

.bacolorgrya{
background-color: rgb(241, 240, 240);
}
.tableroww{
  font-size: 12px !important;
  color: #545353 !important;
}
.tableroww1{
  font-size: 12px !important;
}
/* coffemmet time */
.mornevningbtn{
  
  background-color: #ffffff;
  border: 1px solid #214B0D;
  color: #214B0D;
  border-radius: 5px;
  font-size: 13px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 11px;
    padding-right: 11px;
}
.actmornevningbtn{
  background-color: #214B0D;
  border: 1px solid #214B0D;
  color: #ffffff;
  border-radius: 5px;
  font-size: 13px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 11px;
    padding-right: 11px;

}
.actAmpmbtn{
  background-color: #8d8b8b;
  border: 1px solid #8d8b8b;
  color: #ffffff;
  border-radius: 5px;
  font-size: 13px;
}
.Ampmbtn{
  background-color: #ffffff;
  border: 1px solid rgb(232, 231, 231);
  color: #555555;
  border-radius: 5px;
  font-size: 13px;
}
.actslotclass{
  background-color: #214B0D;
  border: 1px solid #214B0D;
  color: #ffffff;
  border-radius: 5px;
  font-size: 13px;
    
}

.slotclass{
  background-color: #ffffff;
  border: 1px solid rgb(232, 231, 231);
  color: #555555;
  border-radius: 5px;
  font-size: 13px;
}
.dropdowntime{
  padding-top:4px !important;
  padding-bottom:4px !important;

}
.filtercoffeebtn{
  background-color: #999;
  color: #ffffff;
  font-size: 13px;
  border-radius: 15px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  border: 1px solid #999 !important;
}
.filtercoffeebtnact{
  background-color: #214B0D;
  border: 1px solid #214B0D;
  color: #ffffff;
  font-size: 13px;
  border-radius: 15px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
}