body{
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing: 0.3px;
}
.login-main-div{
background-image: url('../images/logback.png') !important;
width: 1519px;
    height: 687px;
    background-repeat: no-repeat;
    background-size: cover;

    
    
}
.abccommuniti-text-main{
    font-family: 'Merriweather Bold', 'Merriweather Regular', 'Merriweather', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    color: #FF8427;
    margin-top: 30px;
    position: absolute;
}
.log-form-div{
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 4px;
    -moz-box-shadow: 5px 5px 5px #214B0D;
    -webkit-box-shadow: 5px 5px 5px rgb(57 24 71);
    box-shadow: 5px 5px 5px rgb(57 24 71);

}
.loginform-first-div{
    height: 42px;
    background: inherit;
    background-color: #214B0D;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 4px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.34901960784313724);
    -webkit-box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
    box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
    font-family: 'Source Sans Pro Bold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    color: #F2F2F2;
    text-align: left;
    padding-top: 10px;
    margin-left: -15px;
    margin-right: -15px;
}
.forgotpasstxt{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: #7F7F7F;
    text-decoration: none !important;
}
.forgotpasstxt:hover{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    text-decoration: none !important;
}
.signinbutton{
    width: 221px;
    height: 40px;
    background: inherit;
    background-color: rgba(238, 118, 0, 1);
    border: none;
    color: #F2F2F2;
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-style: normal;
}
.inputWithIcon {
    position: relative;
  }
  
  .inputWithIcon i {
    position: absolute;
    left: 0;
    top: 8px;
    padding: 9px 8px;
    transition: 0.3s;
     /* padding-left: 30px;  */
     margin-left: 0px;
    margin-bottom: -6px;
    justify-content: center ;
  }
  .inputWithIcon.inputIconBg i {
    background-color: #aaa;
    color: #fff;
    padding: 9px 4px;
    border-radius: 4px 0 0 4px;
  }
  .inputWithIcon input[type="password"] ,input[type="email"]{
    padding-left: 10px;
  }
  .show1{
    margin-left: 390px !important;
    margin-top: 15px !important;
  
  
  }
  .eyeicon{
    position: absolute;
    margin-top: -25px;
    margin-left: 324px;
  }
  .eyeicon1{
    position: absolute;
    margin-top: -25px;
    left: 92%;
    /* margin-left: 424px; */
  }
  .redp{
    font-size: 14px;
    color: red;
    margin-top: 4px;
  }
.forgotpass-instruct-text{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #333333;
}
.forgot-lbl{
    font-family: 'Source Sans Pro SemiBold', 'Source Sans Pro Regular', 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    color: #555555;
}
.forgotcontainer{
    background-color: rgba(247, 247, 247, 1) !important;
margin-top: -23px !important;
height: 711px;

}