
.App{


}
@media  screen 
    and (max-device-width : 575px) 
    and (max-device-height : 994px) 
     {
      
        .join-the-community {
          float: right !important;
          margin-left: 90px !important;
        }
       
        .contactspn{
            margin-left: -12px !important;
        }
        .responsivdispblock{
          display: block !important;
        }
      .community-img{
        width: 300 !important;
      }
      .marginleft20pxforresponsive{
        margin-left: 20px !important;
      }
      .marginleft7pxforresponsive{
        margin-left: 7px !important;

      }
      .txtleftformobres{
       text-align: left !important;     
       }
       .datforreswidth{
        width: 255px !important;
       }
       .widtheightforresponsiv{
        width: 361px !important;
        height: 380px !important;
       }
       
       .backgimg{
        margin-top:40px;
       }
       .marginresltop{
        margin-top: 56px;
       }
       .joinnowresbtn{
        margin-right: 0px !important;
        position: relative !important;
       }
       .signinres{
         margin-left: 20px !important; 
        position: relative !important;
       }
       .downladres{
        margin-left: 40px;
        
       }
       .getiton-googleplay{
        margin-top: 0px !important;
       }
       .MARGINTOPRES{
margin-top: 0px;
       }
       .margtop10{
margin-top: 5px !important;
       }
       .googleplay-div{
        height: 30vh !important;
       }
       .downloadapptxt{
        font-size: 16px !important;
        margin-top: 4px !important;
       }
       .newsletterimg{
        height: 15vh !important;
       }
       .subscribenewsleterbtn{
margin-left: 70px;
font-size: 14px;
width: 120px;
       }
       }

       @media  screen 
       and (max-device-width : 1280px) 
       and (max-device-height : 720px) 
        {
          .kowmor{
      margin-top: 290px !important;
          }
          .join-the-community{
            margin-top: 311px !important;

          }
          .paddingbotomRes{
            padding-bottom: 156px !important;
          }
          .paddingbotomRes1{
            padding-bottom: 90px !important;
          }
          .paddingbotomRes2{
            padding-bottom: 64px !important;
          }
          .borderbott{
            /* width: 844px !important; */
          }
          .borderbott1{
            /* width: 846px !important; */

          }
          .kycbtn{
            width: 261px !important;
          }
          .MARGINTOPRES{
            margin-top: 6px !important;
                   }
                   .dussraimg{
                    width: 306px !important;
                   }
        }  
       
       
      @media  screen 
    and (max-device-width : 1925px) 
    and (max-device-height : 1094px)
    {
      .heightforAll3Div {
        height:539px ;          
     }
    }
    @media  screen 
    and (max-device-width : 2000px) 
    and (max-device-height : 1294px)
    {
      .heightforAll3Div {
        height:573px;          
     }
    }
    @media  screen 
    and (max-device-width : 2100px) 
    and (max-device-height : 1394px)
    {
      .heightforAll3Div {
        height:593px ;          
     }
    }
    @media  screen 
    and (max-device-width : 2200px) 
    and (max-device-height : 1594px)
    {
      .heightforAll3Div {
        height:643px ;          
     }
    }