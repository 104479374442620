/* Popup style */
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    
  }
  
  .box {
    position: relative;
    width: 6%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 14px;
    border: 1px solid #999;
    overflow: auto;
    margin-top: 352px;
    margin-left: 754px;
  }
  
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(45% - 30px);
    top: calc(140vh - 85vh - 33px);
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    font-size: 18px;
  }