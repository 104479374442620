.popup-boxblood {
    position: fixed;
    background: #00000060;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 111;
  }
  
  .boxblood {
    position: relative;
    width: 38%;
    margin: 0 auto;
    height: auto;
    max-height: 60vh;
    margin-top: calc(100vh - 85vh - 20px);
    border-radius: 4px;
    padding: 14px;
    padding-bottom: 0px !important;
    overflow: auto;
    margin-top: 140px;
    margin-left: 432px;


    /* height: 486px; */
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(242, 242, 242, 1);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    

  }
  
  .close-iconblood {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(39% - 45px);
    top: calc(155vh - 129vh - 30px);
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    font-size: 18px;
    /* margin-top: 70px !important; */
  }
  .topnewsrow{
    /* background-color: #214B0D; */
    box-sizing: border-box;
    border-width: 2px;
    border-style: solid;
    border-color: rgb(218, 216, 216);
    border-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    /* font-family: 'Source Sans Pro', sans-serif; */
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    /* color: #F2F2F2; */
  margin-left: -15px;
  margin-right: -14px;
  margin-top: -15px;
  padding-top: 11px;
  padding-bottom: 10px;
  }
  .blood-top-text{
    font-weight: 600;
    font-style: normal;
    font-size: 13px;
    color: #555555;

  }
  .blood-second-text{
    font-weight: 600;
    font-style: normal;
    font-size: 13px;
    color: #555555;
    text-align: right;
  }
  .blood-third-text{
    font-weight: 400;
    font-style: italic;
    font-size: 10px;
    color: #555555;
    text-align: right;
  }
  .cancelbtn{
    width: 160px;
    height: 48px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(181, 146, 76, 1);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #B5924C;


}
.cancelbtn:hover{
    width: 160px;
    height: 48px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(181, 146, 76, 1);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    letter-spacing: 1px;
    color: #B5924C;
}
#bloodrdiodiv input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -4px;
    left: -1px;
    position: relative;
    background-color: lightgray ;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 3px solid lightgray;
}

#bloodrdiodiv input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -4px;
    left: -1px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 3px solid lightgray;
}
.blood-form-lable{
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    color: #555555;
    text-align: right !important;
}
.blood-form-text{
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #555555;
}
.rbt-input-main  {
  font-size: 12px !important;
  color: #555555 !important;

}
.rbt-input{
  font-size: 12px !important;
}
.rbt-input-hint{
  font-size: 12px !important;

}
.rbt-menu {
  font-size: 12px !important;
  display: inline !important;
  width: 250px !important;
  color: #555555 !important;
  transform: translate3d(15.8px, 32px, 0px) !important;
}
.cursorNone
{
  cursor: not-allowed;
  pointer-events: none;
}